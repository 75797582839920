import React from 'react'

var QuestionNumber = React.createClass({

  propTypes: {
    slideTo: React.PropTypes.func,
    style: React.PropTypes.string,
    number: React.PropTypes.number
  },

  render: function () {
    return (
      <div onClick={this.props.slideTo} className={this.props.style}>{this.props.number}</div>
    )
  }

})

export default QuestionNumber
