/* */ 
var Chart = require("./core/core")();
require("./core/core.helpers")(Chart);
require("./platforms/platform")(Chart);
require("./core/core.canvasHelpers")(Chart);
require("./core/core.plugin")(Chart);
require("./core/core.element")(Chart);
require("./core/core.animation")(Chart);
require("./core/core.controller")(Chart);
require("./core/core.datasetController")(Chart);
require("./core/core.layoutService")(Chart);
require("./core/core.scaleService")(Chart);
require("./core/core.ticks")(Chart);
require("./core/core.scale")(Chart);
require("./core/core.title")(Chart);
require("./core/core.legend")(Chart);
require("./core/core.interaction")(Chart);
require("./core/core.tooltip")(Chart);
require("./elements/element.arc")(Chart);
require("./elements/element.line")(Chart);
require("./elements/element.point")(Chart);
require("./elements/element.rectangle")(Chart);
require("./scales/scale.linearbase")(Chart);
require("./scales/scale.category")(Chart);
require("./scales/scale.linear")(Chart);
require("./scales/scale.logarithmic")(Chart);
require("./scales/scale.radialLinear")(Chart);
require("./scales/scale.time")(Chart);
require("./controllers/controller.bar")(Chart);
require("./controllers/controller.bubble")(Chart);
require("./controllers/controller.doughnut")(Chart);
require("./controllers/controller.line")(Chart);
require("./controllers/controller.polarArea")(Chart);
require("./controllers/controller.radar")(Chart);
require("./charts/Chart.Bar")(Chart);
require("./charts/Chart.Bubble")(Chart);
require("./charts/Chart.Doughnut")(Chart);
require("./charts/Chart.Line")(Chart);
require("./charts/Chart.PolarArea")(Chart);
require("./charts/Chart.Radar")(Chart);
require("./charts/Chart.Scatter")(Chart);
window.Chart = module.exports = Chart;
