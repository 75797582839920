/* global localStorage */
import Reflux from 'reflux'
import object from 'lodash/object'

import ExamActions from '../actions/exam-actions'
import QuestionActions from '../actions/question-actions'
import { notifySuccess, notifyError } from '../util/notifier-utils'
import { EXAM_RETRIEVAL_FAILED, EXAM_UPDATE_SUCCESS, TOPICS_RETRIEVAL_FAILED,ADD_TOPIC_FAILED,TOPIC_ADDED_SUCCESS } from '../util/messages'

const ExamStore = Reflux.createStore({
  listenables: [ExamActions],

  currentSubjectTopics: [{
    id: '',
    title: 'Full Exam'
  }],

  getInitialState: function () {
    return {
      isOnboardingHidden: false,
      exams: [],
      selectedOption: 0,
      currentExamType: 0,
      currentSubject: 0,
      currentYear: 0,
      isYearSelectorHidden: true,
      currentSubjectTopics: this.currentSubjectTopics
    }
  },

  onChangeCurrentExam: function (currentExamType) {
    this.trigger({currentExamType})
  },

  onChangeCurrentSubject: function (currentSubject) {
    this.trigger({currentSubject})
  },

  onShowYearSelector: function () {
    let isYearSelectorHidden = false
    this.trigger({isYearSelectorHidden})
  },

  onHideYearSelector: function () {
    let isYearSelectorHidden = true
    this.trigger({isYearSelectorHidden})
  },

  onHideOnboarding: function () {
    let isOnboardingHidden = true
    this.trigger({isOnboardingHidden})
    localStorage.onboarded = true
  },

  onRetrieveExamsCompleted: function (response) {
    response.json().then((exams) => {
      this.trigger({exams: object.values(exams)})
    })
  },

  onRetrieveExamsFailed: function (e) {
    notifyError(EXAM_RETRIEVAL_FAILED)
  },

  onEditExam: function () {
    this.trigger({editMode: true})
  },

  onCancelEdit: function () {
    this.trigger({editMode: false})
  },

  onUpdateExam: function () {
    notifySuccess(EXAM_UPDATE_SUCCESS)
    this.trigger({editMode: true})
  },

  onRetrieveSubjectTopicsCompleted: function (response) {
    response.json().then((topics) => {
      let currentSubjectTopics = this.currentSubjectTopics.concat(topics)
      this.trigger({currentSubjectTopics: currentSubjectTopics})    
    })
  },
  
  onAddNewTopicCompleted: function (response) {
    response.json().then((topic) => {
        let newTopic = [{
            id:topic.id,
            title:topic.title
        }]
        QuestionActions.updateTopic(topic.id)
        // trigger currentsubjecttopics array to re render with new item
    })
  },
  onAddNewTopicFailed: function (e) {
    notifyError(ADD_TOPIC_FAILED)
  },

  onRetrieveSubjectTopicsFailed: function (e) {
    notifyError(TOPICS_RETRIEVAL_FAILED)
  },

})

export default ExamStore
