/* */ 
(function(process) {
  'use strict';
  exports.__esModule = true;
  var _extends = Object.assign || function(target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];
      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }
    return target;
  };
  function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {'default': obj};
  }
  var _invariant = require("invariant");
  var _invariant2 = _interopRequireDefault(_invariant);
  var _ExecutionEnvironment = require("./ExecutionEnvironment");
  var _DOMUtils = require("./DOMUtils");
  var _createHistory = require("./createHistory");
  var _createHistory2 = _interopRequireDefault(_createHistory);
  function createDOMHistory(options) {
    var history = _createHistory2['default'](_extends({getUserConfirmation: _DOMUtils.getUserConfirmation}, options, {go: _DOMUtils.go}));
    function listen(listener) {
      !_ExecutionEnvironment.canUseDOM ? process.env.NODE_ENV !== 'production' ? _invariant2['default'](false, 'DOM history needs a DOM') : _invariant2['default'](false) : undefined;
      return history.listen(listener);
    }
    return _extends({}, history, {listen: listen});
  }
  exports['default'] = createDOMHistory;
  module.exports = exports['default'];
})(require("process"));
