import React from 'react'
import Reflux from 'reflux'
import ReactMixin from 'react-mixin'
import $ from 'jquery'
import bindAll from 'lodash/bindAll'
import { Link } from 'react-router'

import Notifier from '../toolbox/notifier'
import LoginActions from '../../actions/login-actions'
import LoginStore from '../../stores/login-store'
import NotifierStore from '../../stores/notifier-store'
import { cdnImagesUrl } from '../../api/config'

@ReactMixin.decorate(Reflux.ListenerMixin)
@ReactMixin.decorate(Reflux.connect(LoginStore))
class PageHeader extends React.Component {

    constructor(props) {
        super(props)
        bindAll(this)
        this.state = {
            loggedIn: LoginStore.loggedIn()
        }
    }

    static get propTypes() {
        return {
            showLoginButton: React.PropTypes.bool
        }
    }

    static get defaultProps() {
        return {
            showLoginButton: true
        }
    }

    componentDidMount() {
        $('.menu-container').hide()
        this.listenTo(NotifierStore, this.receiveNotification)
    }

    receiveNotification(notification) {
        Notifier.receiveNotification(this.refs.notifier, notification)
    }

    menuClick(event) {
        $('.menu-container').slideToggle('medium')
        event.stopPropagation()
    }

    onClickLogout(provider) {
        LoginActions.logout(provider)
    }

    displayProfileOrLoginLink( {loggedIn, fullName, provider = ''}) {
        if (loggedIn) {
            return <div className='header-user-panel'>
                <p className='name'>{fullName}</p>
                <p>
                    <a href='#' onClick={this.onClickLogout.bind(this, provider.toLowerCase())} className='logout'> Logout</a>
                </p>
            </div>
        }
        if (this.props.showLoginButton) {
            return <Link to='/login' className='header-login-button'>Log In</Link>
    }
    }

    render() {
        var menucontent =
                <div>
                    <div className='menu-item'>
                        <a href='/app'>
                            <img src={`//${cdnImagesUrl}/start_practising.png`}/>
                
                            <h1>Start Practising</h1>
                        </a>
                    </div>
                    <div className='menu-item'>
                        <a href='https://play.google.com/store/apps/details?id=com.flexisaf.cbtmobile' target='_blank'>
                            <img src={`//${cdnImagesUrl}/download_app.png`}/>
                
                            <h1>Mobile App</h1>
                        </a>
                    </div>
                    <div className='menu-item'>
                        <a href='//www.flexisaf.com' target='_blank'>
                            <img src={`//${cdnImagesUrl}/about.png`}/>
                
                            <h1>About FlexiSAF</h1>
                        </a>
                    </div>
                    <div className='menu-item'>
                        <a href='//flexisaf.com/support/' target='_blank'>
                            <img src={`//${cdnImagesUrl}/help.png`}/>
                
                            <h1>Support</h1>
                        </a>
                    </div>
                
                </div>;

        var historycontent = <div>
            <div className='menu-item'>
                <a href='/app/#/history' target='_blank'>
                    <img src={`//${cdnImagesUrl}/cbt-logo.png`}/>
        
                    <h1>History</h1>
                </a>
            </div>
        
        </div>
        
            return (
                    <div>
                        <header>
                            <Notifier ref='notifier'/>
                            <div className='wrap clear'>
                                <div className='logo clear'>
                                    <a href='/'>
                                        <img src={`//${cdnImagesUrl}/cbt-logo.png`}/>
                                        <span>Distinction.ng</span>
                                    </a>
                                </div>
                                <button id='menu-toggle' onClick={this.menuClick}>
                                    <span className='ion-navicon-round'></span> Menu
                                </button>
                                {this.displayProfileOrLoginLink(this.state)}
                                <div className='menu-container' ref='mainMenu'>
                                    <div className='wrap clear'>
                                      {  this.state.loggedIn
                                      ?<div>
                                            {menucontent}
                                            {historycontent}
                                        </div>
                                        :
                                        <div>
                                            {menucontent}
                                        </div>
                                      }
                                    </div>
                                </div>
                            </div>
                        </header>
                    </div>

                    )

        

    }
}

export default PageHeader
