import React from 'react'
import ReactDOM from 'react-dom'
import Reflux from 'reflux'
import bindAll from 'lodash/bindAll'
import ReactMixin from 'react-mixin'
import LinkedStateMixin from 'react-addons-linked-state-mixin'

import LoginPage from '../page/login-page'
import SocialLogin from './social-login'
import LoginStore from '../../stores/login-store'
import LoginActions from '../../actions/login-actions'
import LoginInfo from '../login/login-info'
import LoginLoader from '../toolbox/login-loader'

@ReactMixin.decorate(LinkedStateMixin)
@ReactMixin.decorate(Reflux.connect(LoginStore))
class LoginForm extends React.Component {

  constructor (props) {
    super(props)
    bindAll(this)
    this.state = {
      username: '',
      password: '',
      inProgress: false
    }
  }

  static get propTypes () {
    return {
      switch: React.PropTypes.func.isRequired
    }
  }

  componentDidMount () {
    ReactDOM.findDOMNode(this.refs.email).focus()
  }

  onSubmit (e) {
    e.preventDefault()
    const username = this.state.username
    const password = this.state.password

    LoginActions.login({username, password})
  }

  render () {
    let switchToRegisterForm = this.props.switch.bind(null, LoginPage.formTypes.REGISTER)
    let switchToResetForm = this.props.switch.bind(null, LoginPage.formTypes.RESET)
    return (
      <div>
        {
          this.state.inProgress ? <LoginLoader/> : ''
        }
        <form className='login' onSubmit={this.onSubmit.bind(this)}>
          <div className='login-fields'>
            <input
              type='email'
              ref='email'
              className='field'
              placeholder='Username or Email (required)'
              valueLink={this.linkState('username')}
              required='required'
              />
            <input
              type='password'
              className='field'
              placeholder='Password (required)'
              valueLink={this.linkState('password')}
              required='required'
              />
          </div>
          <input type='submit' className='login-button' value='Log In'/>
          <br/>
          Don't have an account? <a href='#' onClick={switchToRegisterForm}>Create an account.</a>
          <div>
            <a href='#' onClick={switchToResetForm}>Forgot your password?</a>
          </div>
          <SocialLogin/>
        </form>
        <LoginInfo/>
      </div>
    )
  }
}

export default LoginForm
