import React from 'react'
import ClassNames from 'classnames'

import QuestionActions from '../../actions/question-actions'
import '../../../css/question-and-answers.css!'

const QuestionBookmark = React.createClass({

  propTypes: {
    metadata: React.PropTypes.object.isRequired
  },

  render: function () {
    let metadata = this.props.metadata
    let questionIndex = metadata.questionIndex
    let classString = ClassNames({
      'icon' : true,
      'ion-flag' : true,
      'bookmark' : metadata.isBookmarked
    })
    return (
      <div className='action-button' title='Bookmark this question.'
           onClick={QuestionActions.bookmarkQuestion.bind(this, questionIndex)}>
        <span className={classString}></span>
      </div>
    )
  }
})

export default QuestionBookmark
