import React from 'react'

import GoogleLogin from './google-login'
import FacebookLogin from './facebook-login'

export default class SocialLogin extends React.Component {
  render () {
    return (
      <div className='social-login-wrap'>
        <FacebookLogin/>
        <GoogleLogin/>
        <div className='clear'></div>
      </div>
    )
  }
}
