export const PASSWORD_MISMATCH = `Passwords don't match.`
export const SIGNUP_FAILED = 'Sign up failed'
export const SIGNUP_FAILED_MESSAGE = 'Please try again or contact our support.'
export const SIGNUP_INVALID_EMAIL = 'Account email address is in an invalid format.'
export const SIGNUP_EMAIL_EXISTS = 'An account with that email already exists. Please choose another email or contact our support.'
export const SIGNUP_VERIFY_EMAIL = 'Check the verification email we just sent you to confirm your registration.'
export const SIGNUP_SUCCESS = 'Welcome! You have signed up successfully.'
export const LOGIN_FAILED = 'Log in failed'
export const LOGIN_FAILED_MESSAGE = 'Incorrect email or password.'
export const LOGIN_FAILED_FACEBOOK = 'Failed to log in via Facebook. Please try again or contact our support.'
export const LOGIN_FAILED_GOOGLE = 'Failed to log in via Google. Please try again or contact our support.'
export const LOGIN_SUCCESS = 'You are now logged in!'
export const LOGOUT_SUCCESS = 'You have been logged out successfully!'
export const LOGOUT_FAILED = 'Log out failed. Please try again or contact our support.'
export const USER_PROFILE_FAILED = 'Unable to retrieve user profile. Please try again or contact our support.'
export const FACEBOOK_UNAUTHORIZED = 'Please log into Distinction via Facebook.'
export const FACEBOOK_NOT_LOGGED_IN = 'Please log into Facebook.'
export const GOOGLE_SIGNIN_FAILED = 'Google sign-in failed. Please ensure you have a valid Google account sign-in.'
export const EXAM_RETRIEVAL_FAILED = 'Failed to retrieve exams.'
export const QUESTION_UPDATE_SUCCESS = 'This question has been updated successfully!'
export const TOPIC_ADDED_SUCCESS = 'This topic has been added successfully!'
export const QUESTION_UPDATE_FAILED = 'Failed to update question.'
export const QUESTION_RETRIEVAL_FAILED = 'Failed to retrieve questions.'
export const PRO_TIP = 'Pro Tip'
export const PRO_TIP_PROGRESS_TRACKING = `<a href='/app/#/login?signup=true'>Sign up</a> for an account or <a href='/app/#/login'>log in</a> to track your progress.`
export const ADD_TOPIC_FAILED = 'Failed to add topic.'
export const TOPICS_RETRIEVAL_FAILED = 'Failed to retrieve topics.'
