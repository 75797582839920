import React from 'react'
import Reflux from 'reflux'
import ReactMixin from 'react-mixin'
import bindAll from 'lodash/bindAll'
import DocumentTitle from 'react-document-title'


import LoginActions from '../../actions/login-actions'
import Bar from '../charts/bar'
import HistoryStore from '../../stores/history-store'
import QuestionStore from '../../stores/question-store'
import PageHeader from './page-header'
import HistoryActions from '../../actions/history-actions'

import '../../../css/history.css!'



const History = React.createClass({
    
    
  mixins: [Reflux.connect(HistoryStore) ],  
  getInitialState: function() {
    return {
        historyObjects: []        
    };
  },

componentWillMount(){   
    HistoryActions.userProfile();
},
    
  render() {
                               
const waec = {
  labels: ['English', 'Math', 'Physics', 'Geography', 'Chemistry', 'Physics', 'Biology','Agric', 'Commerce', 'Literature', 'Further Math', 'Civic', 'CRK', 'IRK'],
  datasets: [
    {
      label: 'Waec',
      backgroundColor: 'rgba(28, 117, 138, 1)',
      borderColor: 'rgba(28, 117, 138, 0.4)',
      borderWidth: 1,
      hoverBackgroundColor: 'rgba(28, 117, 138, 0.8)',
      hoverBorderColor: 'rgba(28, 117, 138, 0.2)',
      data: [65, 59, 80, 81, 56, 55, 40,65, 59, 80, 81, 56, 55, 40],
    }
  ]
}
const jamb = {
  labels: ['English', 'Math', 'Physics', 'Geography', 'Chemistry', 'Physics', 'Biology','Economics'],
  datasets: [
    {
      label: 'Jamb',
      backgroundColor: 'rgba(230, 126, 34, 1)',
      borderColor: 'rgba(230, 126, 34, 1)',
      borderWidth: 4,
      hoverBackgroundColor: 'rgba(230, 126, 34, 0.8)',
      hoverBorderColor: 'rgba(230, 126, 34, 1)',
      data: [85, 89, 40, 81, 26, 55, 90, 25],
    }
  ]
}
const optionsJamb = {
    
    scales: {
        yAxes: [{
            ticks: {
                beginAtZero: true
            }
        }],   
        xAxes: [
                    {             
                        barPercentage: 6.0,
                        barThickness : 50            
                    }
                ]
        
    },    
}  

const optionsWaec = {    
    scales: {
        yAxes: [{
            ticks: {
                beginAtZero: true
            }
        }],   
        xAxes: [
                    {             
                        barPercentage: 6.0,
                        barThickness : 35            
                    }
                ]
        
    },
    
    
}  
    return (
            <div>
              <PageHeader/>  
              <Bar data={waec} options={optionsWaec} />
              <Bar data={jamb} options={optionsJamb} />    
            </div>
    )
  }
});




export default History
