import React from 'react'
import ReactDOM from 'react-dom'
import ReactMixin from 'react-mixin'
import Editor from 'react-medium-editor'
import Reflux from 'reflux'
import bindAll from 'lodash/bindAll'
import $ from 'jquery'
import { Lifecycle } from 'react-router'

import ExamHeader from './exam-header'
import ExamContent from './exam-content'
import ExamResult from './exam-result'
import ExamStore from '../../stores/exam-store'
import QuestionStore from '../../stores/question-store'
import QuestionActions from '../../actions/question-actions'
import { RIGHT_ARROW, LEFT_ARROW } from '../../util/keyboard-key-codes'

import '../../../css/style.css!'
import '../../../css/ionicons.min.css!'
import 'medium-editor/dist/css/medium-editor.css!'
import 'medium-editor/dist/css/themes/flat.css!'

@ReactMixin.decorate(Lifecycle)
@ReactMixin.decorate(Reflux.connect(ExamStore))
class Exam extends React.Component {

  constructor (props, context) {
    super(props, context)
    bindAll(this)
    this.state = {
      editMode: false,
      keydownEvent: 'keydown'
    }
  }

  static get propTypes () {
    return {
      params: React.PropTypes.object.isRequired
    }
  }

  /**
   * Prompt the user before leaving the exam using a React Router transition hook.
   *
   * @returns {string}
   */
  routerWillLeave () {
    if (!QuestionStore.isExamFinished) {
      $('body').addClass('blur')
      return 'You have not finished your exam! Are you sure you want to leave?'
    }
  }

  unblur () {
    setTimeout(function () { $('body').removeClass('blur') }, 1000)
  }

  /**
   * This specifies the names and types of values that can be made available to descendant components.
   *
   * @returns {{editMode: boolean, editableText: Function}}
   */
  static get childContextTypes () {
    return {
      editMode: React.PropTypes.bool.isRequired,
      editableText: React.PropTypes.func.isRequired
    }
  }

  /**
   * The return values will be made available to descendant components that opt in.
   * A descendant component can opt to receive certain values by specifying them in its `contextTypes`.
   *
   * Example:
   *  `contextTypes: {
   *     editMode: React.PropTypes.bool.isRequired
   *   }`
   *
   * @returns {{editMode: boolean, editableText: Function}}
   */
  getChildContext () {
    const editableText = this.editableText
    return {
      editMode: this.state.editMode,
      editableText
    }
  }

  /**
   * This is a curried function, so it is meant to be called twice.
   * Usage: `editableText(fn)(text)`
   *
   * Example 1:
   *  `const editable = this.context.editableText((text) => { console.log(text) })
   *  editable('Lorem Ipsum Dolor')`
   *
   * Example 2:
   *  `this.context.editableText((text) => { console.log(text) })('Lorem Ipsum Dolor')`
   *
   * @param fn
   * @returns {Function}
   */
  editableText (fn) {
    return (text) => {
      return (
        <Editor
          text={text}
          onChange={fn}
          options={{toolbar: {buttons: ['bold', 'italic', 'underline']}}}
          />
      )
    }
  }

  componentDidMount () {
    ReactDOM.findDOMNode(this.refs.examDiv).focus()
    this.enableKeyboardNavigation()
  }

  componentWillUnmount () {
    $('body').removeClass('blur')
    this.disableKeyboardNavigation()
  }

  componentWillUpdate (nextProps, nextState) {
    nextState.editMode ? this.disableKeyboardNavigation() : this.enableKeyboardNavigation()
  }

  /**
   * Enable keyboard navigation using the arrow keys.
   */
  enableKeyboardNavigation () {
    $(document.body).off(this.state.keydownEvent).on(this.state.keydownEvent, this.handleKeyDown)
  }

  /**
   * Disable keyboard navigation using the arrow keys.
   */
  disableKeyboardNavigation () {
    $(document.body).off(this.state.keydownEvent, this.handleKeyDown)
  }

  handleKeyDown (event) {
    let keyCode = event.keyCode
    switch (keyCode) {
      case RIGHT_ARROW:
        QuestionActions.nextQuestion()
        break
      case LEFT_ARROW:
        QuestionActions.prevQuestion()
        break
    }
  }

  render () {
    return (
      <div tabIndex='0'
           ref='examDiv'
           value=''
           onMouseMove={this.unblur}>
        <ExamHeader
          exam={this.props.params.exam}
          subject={this.props.params.subject}
          year={this.props.params.year}
          />
        <ExamContent
          exam={this.props.params.exam}
          subject={this.props.params.subject}
          year={this.props.params.year}
          topic={this.props.params.topic}
          />
        <ExamResult
          exam={this.props.params.exam}
          subject={this.props.params.subject}
        />
      </div>
    )
  }
}

export default Exam
