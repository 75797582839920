/* */ 
var _ = require("./utils"),
    Reflux = require("./index"),
    Keep = require("./Keep"),
    mixer = require("./mixer"),
    allowed = {
      preEmit: 1,
      shouldEmit: 1
    },
    bindMethods = require("./bindMethods");
module.exports = function(definition) {
  definition = definition || {};
  for (var a in Reflux.StoreMethods) {
    if (!allowed[a] && (Reflux.PublisherMethods[a] || Reflux.ListenerMethods[a])) {
      throw new Error("Cannot override API method " + a + " in Reflux.StoreMethods. Use another method name or override it on Reflux.PublisherMethods / Reflux.ListenerMethods instead.");
    }
  }
  for (var d in definition) {
    if (!allowed[d] && (Reflux.PublisherMethods[d] || Reflux.ListenerMethods[d])) {
      throw new Error("Cannot override API method " + d + " in store creation. Use another method name or override it on Reflux.PublisherMethods / Reflux.ListenerMethods instead.");
    }
  }
  definition = mixer(definition);
  function Store() {
    var i = 0,
        arr;
    this.subscriptions = [];
    this.emitter = new _.EventEmitter();
    this.eventLabel = "change";
    bindMethods(this, definition);
    if (this.init && _.isFunction(this.init)) {
      this.init();
    }
    if (this.listenables) {
      arr = [].concat(this.listenables);
      for (; i < arr.length; i++) {
        this.listenToMany(arr[i]);
      }
    }
  }
  _.extend(Store.prototype, Reflux.ListenerMethods, Reflux.PublisherMethods, Reflux.StoreMethods, definition);
  var store = new Store();
  Keep.createdStores.push(store);
  return store;
};
