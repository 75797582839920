import React from 'react'
import Reflux from 'reflux'
import ReactMixin from 'react-mixin'
import bindAll from 'lodash/bindAll'
import DocumentTitle from 'react-document-title'

import PageHeader from '../page/page-header'
import LoginForm from '../login/login-form'
import RegistrationForm from '../login/registration-form'
import ResetPasswordForm from '../login/reset-password-form'
import LoginStore from '../../stores/login-store'

import '../../../css/login.css!'

@ReactMixin.decorate(Reflux.connect(LoginStore))
class LoginPage extends React.Component {

  constructor (props) {
    super(props)
    bindAll(this)
    this.state = {
      signup: false,
      loggedIn: false,
      formType: LoginPage.formTypes.LOGIN
    }
  }

  static get propTypes () {
    return {
      params: React.PropTypes.object.isRequired,
      history: React.PropTypes.object.isRequired,
      location: React.PropTypes.object.isRequired
    }
  }

  static get formTypes () {
    return {
      LOGIN: 'login',
      REGISTER: 'register',
      RESET: 'reset'
    }
  }

  /**
   * React lifecycle function.
   *
   * If the URL has a query parameter `signup` that is set to true,
   * i.e. `?signup=true`, we render the registration form.
   */
  componentDidMount () {
    if (this.props.location.query.signup) {
      this.setState({signup: true})
    }
  }

  componentDidUpdate () {
    if (this.state.loggedIn) {
      this.props.history.pushState(null, '/')
    }
  }

  /**
   * Gets called from within a form to switch to a different form.
   *
   * @param formType Type of the form to switch to, it should be one of LoginPage.formTypes.
   * @param e Event triggered by form switch action.
   */
  switchForm (formType, e) {
    e.preventDefault()

    if (this.state.signup && (formType !== LoginPage.formTypes.REGISTER)) {
      this.setState({signup: false})
    }

    this.setState({formType})
  }

  /**
   * Returns a React component based on the form type handed to it.
   *
   * @param formType Form type that is one of LoginPage.formTypes.
   * @returns {*}
   */
  loadForm (formType) {
    let form = null
    let switchForm = this.switchForm.bind(this)

    const {LOGIN, REGISTER, RESET} = LoginPage.formTypes

    switch (formType) {
      case LOGIN:
        form = <LoginForm switch={switchForm}/>
        break
      case REGISTER:
        form = <RegistrationForm switch={switchForm} history={this.props.history}/>
        break
      case RESET:
        form = <ResetPasswordForm switch={switchForm}/>
        break
    }
    return form
  }

  render () {
    let form = null
    if (this.state.signup) {
      form = this.loadForm(LoginPage.formTypes.REGISTER)
    } else {
      form = this.loadForm(this.state.formType)
    }
    return (
      <DocumentTitle title={`Distinction.ng | Log In`}>
        <div>
          <PageHeader showLoginButton={false}/>
          <div className='login-wrap clear'>
            {form}
          </div>
        </div>
      </DocumentTitle>
    )
  }
}

export default LoginPage
