/* global localStorage */
import Reflux from 'reflux'
import object from 'lodash/object'

import HistoryActions from '../actions/history-actions'
import { notifySuccess, notifyError } from '../util/notifier-utils'
import { EXAM_RETRIEVAL_FAILED, EXAM_UPDATE_SUCCESS } from '../util/messages'

const HistoryStore = Reflux.createStore({
  listenables: [HistoryActions],
  historyObjects: [],

  
  onRetrieveHistoryCompleted: function (response) {
    let historyObjects = this.historyObjects;
      
    response.json().then((historyObjects) => {
 
      historyObjects.map((histobj) => {
          
            this.historyObjects.push(histobj)
            
        });       
        this.trigger({historyObjects});
        console.log(this.historyObjects);
    });
  },
  onUserProfileCompleted: function (response) {
      let email = this.email;     
      if (response.ok) {
          response.json().then((profile) => {
          email = profile.email;
          HistoryActions.retrieveHistory(email)        
      })
      } else 
        {
            notifyError(USER_PROFILE_FAILED)
        }
  },
  onUserProfileFailed: function () {
      notifyError(USER_PROFILE_FAILED)
  },
  onRetrieveHistoryFailed: function (e) {
      notifyError(EXAM_RETRIEVAL_FAILED)
  },



})

export default HistoryStore
