/* */ 
(function(process) {
  'use strict';
  exports.__esModule = true;
  function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {'default': obj};
  }
  var _warning = require("warning");
  var _warning2 = _interopRequireDefault(_warning);
  var _extractPath = require("./extractPath");
  var _extractPath2 = _interopRequireDefault(_extractPath);
  function parsePath(path) {
    var pathname = _extractPath2['default'](path);
    var search = '';
    var hash = '';
    process.env.NODE_ENV !== 'production' ? _warning2['default'](path === pathname, 'A path must be pathname + search + hash only, not a fully qualified URL like "%s"', path) : undefined;
    var hashIndex = pathname.indexOf('#');
    if (hashIndex !== -1) {
      hash = pathname.substring(hashIndex);
      pathname = pathname.substring(0, hashIndex);
    }
    var searchIndex = pathname.indexOf('?');
    if (searchIndex !== -1) {
      search = pathname.substring(searchIndex);
      pathname = pathname.substring(0, searchIndex);
    }
    if (pathname === '')
      pathname = '/';
    return {
      pathname: pathname,
      search: search,
      hash: hash
    };
  }
  exports['default'] = parsePath;
  module.exports = exports['default'];
})(require("process"));
