import React from 'react'

import QuestionActions from '../../actions/question-actions'

const QuestionChoice = React.createClass({

  propTypes: {
    choiceIndex: React.PropTypes.number.isRequired,
    text: React.PropTypes.string.isRequired,
    metadata: React.PropTypes.object.isRequired
  },

  contextTypes: {
    editMode: React.PropTypes.bool.isRequired,
    editableText: React.PropTypes.func.isRequired
  },

  handleChoiceEdit: function (editedChoice) {
    QuestionActions.setEditedChoice(editedChoice, this.props.choiceIndex)
  },

  renderHTML: function () {
    return {__html: this.props.text}
  },

  render: function () {
    let classString = ''
    let metadata = this.props.metadata
    let questionIndex = metadata.questionIndex
    if (metadata.isAnswered === true && this.props.choiceIndex === metadata.selectedAnswerIndex) {
      classString += 'selected'
    }
    const editableChoice = this.context.editableText(this.handleChoiceEdit)
    return (
      <li
        onClick={QuestionActions.selectAnswer.bind(this, questionIndex, this.props.choiceIndex)}
        className={classString}>
        {
          this.context.editMode
          ? <span>{editableChoice(this.props.text)}</span>
          : <span dangerouslySetInnerHTML={this.renderHTML()}/>
        }
      </li>
    )
  }
})

export default QuestionChoice
