/* global FB, gapi */
import Reflux from 'reflux'

import { googleAppClientId } from '../api/config'
import { login, logout, register, me, facebookLogin, googleLogin, forgot } from '../api/login-api'

const LoginActions = Reflux.createActions({
  login: {children: ['completed', 'failed', 'progressed']},
  logout: {children: ['completed', 'failed']},
  register: {children: ['completed', 'progressed']},
  userProfile: {children: ['completed', 'failed']},
  facebookLogin: {children: ['completed', 'failed']},
  facebookDirectLogin: {children: ['completed']},
  facebookStatusLogin: {children: ['completed', 'progressed']},
  googleLogin: {children: ['completed', 'failed']},
  googleDirectLogin: {children: ['completed', 'progressed']},
  forgotPassword: {children: ['completed']}
})

LoginActions.login.listen(function (credentials) {
  this.progressed()
  return login(credentials)
    .then(this.completed)
    .catch(this.failed)
})

LoginActions.logout.listen(function () {
  return logout()
    .then(this.completed)
    .catch(this.failed)
})

LoginActions.register.listen(function (credentials) {
  this.progressed()
  // Register does not return a proper response,
  // hence even a failure cannot be trusted.
  return register(credentials)
    .then(this.completed)
    .catch(this.completed)
})

LoginActions.userProfile.listen(function () {
  return me()
    .then(this.completed)
    .catch(this.failed)
})

LoginActions.facebookLogin.listen(function (credentials) {
  return facebookLogin(credentials)
    .then(this.completed)
    .catch(this.failed)
})

LoginActions.facebookStatusLogin.listen(function () {
  this.progressed()
  return FB.getLoginStatus(this.completed, true)
})

LoginActions.facebookDirectLogin.listen(function () {
  return FB.login(this.completed, {scope: 'email'})
})

LoginActions.googleLogin.listen(function (credentials) {
  return googleLogin(credentials)
    .then(this.completed)
    .catch(this.failed)
})

LoginActions.googleDirectLogin.listen(function () {
  this.progressed()
  let scopes = 'email'
  return gapi.auth.authorize({
    client_id: googleAppClientId,
    scope: scopes,
    immediate: false
  }, this.completed)
})

LoginActions.forgotPassword.listen(function (email) {
  // Register does not return a proper response,
  // hence even a failure cannot be trusted.
  return forgot(email)
    .then(this.completed)
    .catch(this.completed)
})

export default LoginActions
