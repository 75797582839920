import React from 'react'
import '../../../css/question-and-answers.css!'

const ReportQuestion = React.createClass({
  render: function () {
    return (
      <div className='action-button'><span className='icon ion-alert-circled'></span>Report
        <span className='report-popout'>
          <select>
            <option>Select Type of Issue</option>
            <option> Spelling Mistake </option>
            <option> Grammatical Error</option>
          </select>
          <textarea rows='5' placeholder='Additional Details'></textarea>
          <button>Cancel</button>
          <button>Submit</button>
        </span>
      </div>
    )
  }
})

export default ReportQuestion
