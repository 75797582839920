import React from 'react'

import ExamType from './exam-type'

import '../../../css/exam-selection.css!'

const ExamSelector = React.createClass({

  propTypes: {
    exams: React.PropTypes.array
  },

  render: function () {
    var examType = this.props.exams.map(function (exam, key) {
      return (
        <ExamType title={exam.title} index={key} key={key} />
      )
    })
    return (
      <div className='exams'>
        <ul>
          {examType}
        </ul>
      </div>
    )
  }
})

export default ExamSelector
