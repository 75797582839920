import React from 'react'
import Reflux from 'reflux'
import ReactMixin from 'react-mixin'
import bindAll from 'lodash/bindAll'

import LoginPage from '../page/login-page'
import LoginStore from '../../stores/login-store'
import LoginActions from '../../actions/login-actions'

@ReactMixin.decorate(Reflux.connect(LoginStore))
class ResetPasswordForm extends React.Component {

  constructor (props) {
    super(props)
    bindAll(this)
    this.state = {
      fields: {
        email: ''
      },
      isRequestSent: false,
      inProgress: false
    }
  }

  static get propTypes () {
    return {
      switch: React.PropTypes.func.isRequired
    }
  }

  onFormSubmit (e) {
    e.preventDefault()

    const email = this.state.email
    LoginActions.forgotPassword(email)
  }

  onEmailChanged (e) {
    this.state.email = e.target.value
  }

  render () {
    const switchToLoginForm = this.props.switch.bind(null, LoginPage.formTypes.LOGIN)
    return (
      <div className='row'>
        { this.state.isRequestSent
          ? <div>
              <h3>
                We have sent a password reset link to the email address of the account that you specified.
                Please check your email for this message, then click on the link.
              </h3>
              <p>
                <a href='#' onClick={switchToLoginForm}>Back to Login</a>
              </p>
            </div>
          : <form className='login' onSubmit={this.onFormSubmit.bind(this)}>
              <div className='login-fields'>
                <input
                  type='email'
                  ref='email'
                  className='field'
                  placeholder='Email (required)'
                  onChange={this.onEmailChanged.bind(this)}
                  required='required'
                  />
              </div>
              <div>
                <input type='submit' className='login-button' value='Request Password Reset'/>
              </div>
              <br/>
              <div>
                <a href='#' onClick={switchToLoginForm}>Back to Login</a>
              </div>
            </form>
        }
      </div>
    )
  }
}

export default ResetPasswordForm
