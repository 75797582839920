/* */ 
var eq = require("../eq"),
    isArrayLike = require("../isArrayLike"),
    isIndex = require("./isIndex"),
    isObject = require("../isObject");
function isIterateeCall(value, index, object) {
  if (!isObject(object)) {
    return false;
  }
  var type = typeof index;
  if (type == 'number' ? (isArrayLike(object) && isIndex(index, object.length)) : (type == 'string' && index in object)) {
    return eq(object[index], value);
  }
  return false;
}
module.exports = isIterateeCall;
