import React from 'react'
import classNames from 'classnames'

const ReviewOption = React.createClass({

  propTypes: {
    optionText: React.PropTypes.string.isRequired,
    correctAnswer: React.PropTypes.number.isRequired,
    choiceIndex: React.PropTypes.number.isRequired,
    metadata: React.PropTypes.object.isRequired
  },

  renderHTML: function () {
    return {__html: this.props.optionText}
  },

  render: function () {
    let selected = this.props.choiceIndex === this.props.metadata.selectedAnswerIndex
    let passed = this.props.choiceIndex === this.props.correctAnswer
    let failed = this.props.choiceIndex !== this.props.correctAnswer
    let classString = classNames({
      'option': true,
      'selected': selected,
      'passed': passed,
      'failed': selected && failed
    })
    let tickmark
    if (selected && passed) {
      tickmark = <span className='ion-checkmark-round tick'></span>
    }
    if (selected && failed) {
      tickmark = <span className='ion-close-round tick'></span>
    }
    return (
      <div className={classString}>
        <span dangerouslySetInnerHTML={this.renderHTML()}/>
        {tickmark}
      </div>
    )
  }
})

export default ReviewOption
