import React from 'react'

import Subject from './subject'
import '../../../css/style.css!'
import '../../../css/exam-selection.css!'

const SubjectSelector = React.createClass({
  propTypes: {
    exams: React.PropTypes.array,
    currentExamType: React.PropTypes.number,
    className: React.PropTypes.string
  },

  render: function () {
    const exam = this.props.exams[this.props.currentExamType]
    return (
      <div className={this.props.className}>
        <div className='search-box clear'>
          <h1>{exam.title}</h1>
        </div>
        <div className='subjects-box'>
           <ul>
            {
              exam.subjects.map(function (subject, key) {
                return <Subject title={subject.title} index={key} key={key} />
              })
            }
            </ul>
        </div>
      </div>
    )
  }

})

export default SubjectSelector
