import React from 'react'

const Year = React.createClass({
  propTypes: {
    year: React.PropTypes.string.isRequired
  },

  render: function () {
    return (
      <option value={this.props.year}>
        {this.props.year}
      </option>
    )
  }

})

export default Year
