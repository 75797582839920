import React from 'react'

import ExamActions from '../../actions/exam-actions'

const Subject = React.createClass({
  propTypes: {
    title: React.PropTypes.string,
    index: React.PropTypes.number
  },

  onChangeCurrentSubject: function () {
    ExamActions.changeCurrentSubject(this.props.index)
    ExamActions.showYearSelector()
    ExamActions.retrieveSubjectTopics(this.props.title)
  },

  render: function () {
    return (
      <li onClick={this.onChangeCurrentSubject}>
        <span>{this.props.title}</span>
      </li>
    )
  }

})

export default Subject
