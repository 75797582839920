import React from 'react'
import ReviewOption from './review-option'

const ReviewOptionGroup = React.createClass({
  propTypes: {
    options: React.PropTypes.array.isRequired,
    questionMetadata: React.PropTypes.object.isRequired,
    correctAnswer: React.PropTypes.number.isRequired
  },
  render: function () {
    return (
      <ul>
        {
          this.props.options.map((option, key) =>
            <ReviewOption optionText={option.text}
            metadata={this.props.questionMetadata}
            choiceIndex={key}
            key = {key}
            correctAnswer={this.props.correctAnswer}
            />
          )
        }
      </ul>
    )
  }
})

export default ReviewOptionGroup
