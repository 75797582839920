import React from 'react'
import '../../../css/loader.css!'

var Loader = React.createClass({
  render: function () {
    return (
      <div className='loader-wrap'>
        <div className='loader'></div>
      </div>
      )
  }
})

export default Loader
