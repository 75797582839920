/* */ 
(function(process) {
  'use strict';
  var DOMLazyTree = require("./DOMLazyTree");
  var Danger = require("./Danger");
  var ReactMultiChildUpdateTypes = require("./ReactMultiChildUpdateTypes");
  var ReactPerf = require("./ReactPerf");
  var setInnerHTML = require("./setInnerHTML");
  var setTextContent = require("./setTextContent");
  function getNodeAfter(parentNode, node) {
    return node ? node.nextSibling : parentNode.firstChild;
  }
  function insertChildAt(parentNode, childNode, referenceNode) {
    parentNode.insertBefore(childNode, referenceNode);
  }
  function insertLazyTreeChildAt(parentNode, childTree, referenceNode) {
    DOMLazyTree.insertTreeBefore(parentNode, childTree, referenceNode);
  }
  var DOMChildrenOperations = {
    dangerouslyReplaceNodeWithMarkup: Danger.dangerouslyReplaceNodeWithMarkup,
    updateTextContent: setTextContent,
    processUpdates: function(parentNode, updates) {
      for (var k = 0; k < updates.length; k++) {
        var update = updates[k];
        switch (update.type) {
          case ReactMultiChildUpdateTypes.INSERT_MARKUP:
            insertLazyTreeChildAt(parentNode, update.content, getNodeAfter(parentNode, update.afterNode));
            break;
          case ReactMultiChildUpdateTypes.MOVE_EXISTING:
            insertChildAt(parentNode, update.fromNode, getNodeAfter(parentNode, update.afterNode));
            break;
          case ReactMultiChildUpdateTypes.SET_MARKUP:
            setInnerHTML(parentNode, update.content);
            break;
          case ReactMultiChildUpdateTypes.TEXT_CONTENT:
            setTextContent(parentNode, update.content);
            break;
          case ReactMultiChildUpdateTypes.REMOVE_NODE:
            parentNode.removeChild(update.fromNode);
            break;
        }
      }
    }
  };
  ReactPerf.measureMethods(DOMChildrenOperations, 'DOMChildrenOperations', {updateTextContent: 'updateTextContent'});
  module.exports = DOMChildrenOperations;
})(require("process"));
