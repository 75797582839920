import React from 'react'

const Topic = React.createClass({
  propTypes: {
    id: React.PropTypes.string.isRequired,
    title: React.PropTypes.string.isRequired
  },

  render: function () {
    return (
        <option value={this.props.id}>
          {this.props.title}
        </option>
    )
  }

})

export default Topic
