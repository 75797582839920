import Reflux from 'reflux'

import { exams } from '../api/exam-api'
import { subjectTopics,addNewTopic } from '../api/topic-api'

const ExamActions = Reflux.createActions([
  'changeCurrentExam',
  'changeCurrentSubject',
  'showYearSelector',
  'hideYearSelector',
  'hideOnboarding',
  'retrieveExams',
  'addNewTopic',
  'editExam',
  'updateExam',
  'cancelEdit',
  'retrieveSubjectTopics'
])

ExamActions.retrieveExams = Reflux.createAction({asyncResult: true})
ExamActions.retrieveSubjectTopics = Reflux.createAction({asyncResult: true})
ExamActions.addNewTopic = Reflux.createAction({asyncResult: true})

ExamActions.retrieveExams.listen(function () {
  return exams()
    .then(this.completed)
    .catch(this.failed)
})

ExamActions.retrieveSubjectTopics.listen(function (subject) {
  return subjectTopics(subject)
      .then(this.completed)
      .catch(this.failed)
})

ExamActions.addNewTopic.listen(function (topic) {
    return addNewTopic(topic)
      .then(this.completed)
      .catch(this.failed)
})

export default ExamActions
