/* */ 
'use strict';
exports.__esModule = true;
var _extends = Object.assign || function(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i];
    for (var key in source) {
      if (Object.prototype.hasOwnProperty.call(source, key)) {
        target[key] = source[key];
      }
    }
  }
  return target;
};
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {'default': obj};
}
var _Actions = require("./Actions");
var _parsePath = require("./parsePath");
var _parsePath2 = _interopRequireDefault(_parsePath);
function createLocation() {
  var location = arguments.length <= 0 || arguments[0] === undefined ? '/' : arguments[0];
  var action = arguments.length <= 1 || arguments[1] === undefined ? _Actions.POP : arguments[1];
  var key = arguments.length <= 2 || arguments[2] === undefined ? null : arguments[2];
  var _fourthArg = arguments.length <= 3 || arguments[3] === undefined ? null : arguments[3];
  if (typeof location === 'string')
    location = _parsePath2['default'](location);
  if (typeof action === 'object') {
    location = _extends({}, location, {state: action});
    action = key || _Actions.POP;
    key = _fourthArg;
  }
  var pathname = location.pathname || '/';
  var search = location.search || '';
  var hash = location.hash || '';
  var state = location.state || null;
  return {
    pathname: pathname,
    search: search,
    hash: hash,
    state: state,
    action: action,
    key: key
  };
}
exports['default'] = createLocation;
module.exports = exports['default'];
