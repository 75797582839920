import React from 'react'

import LoginActions from '../../actions/login-actions'

export default class FacebookLogin extends React.Component {
  render () {
    return (
      <div>
        <div className='social-login' onClick={LoginActions.facebookStatusLogin}>
          <span className='icon ion-social-facebook'></span> Facebook
        </div>
      </div>
    )
  }
}
