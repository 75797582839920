 import React from 'react'
import ReactDOM from 'react-dom'
import { Router, Route, IndexRoute } from 'react-router'
import createHashHistory from 'history/lib/createHashHistory'

import Exam from './components/exam/exam'
import ExamSelection from './components/exam/exam-selection'
import ExamReview from './components/review/exam-review'
import LoginPage from './components/page/login-page'
import History from './components/page/history'
import Bar from './components/charts/bar'
import LoginActions from './actions/login-actions'

const history = createHashHistory({ queryKey: false })

const App = React.createClass({

  propTypes: {
    children: React.PropTypes.object
  },

  render: function () {
    return (
      <div>
        {this.props.children}
      </div>
    )
  }
})

function getUserProfile () {
  LoginActions.userProfile()
}

const routes = (
  <Router history={history}>
    <Route path='/' component={App}>
      <IndexRoute component={ExamSelection} onEnter={getUserProfile}/>
      <Route path='login' component={LoginPage}/>
      <Route path='exams' component={ExamSelection} onEnter={getUserProfile}/>
      <Route path='exam/:exam/:subject/:year' component={Exam} onEnter={getUserProfile}/>
      <Route path='exam/:exam/:subject/:year/:topic' component={Exam} onEnter={getUserProfile}/>
      <Route path='review' component={ExamReview}/>
      <Route path='history' component={History} onEnter={getUserProfile}/>
      <Route path='bar' component={Bar} onEnter={getUserProfile}/>
    </Route>
  </Router>
)

const start = () => {
  ReactDOM.render(routes, document.getElementById('app'))
}

start()
