import { apiUrl } from './config'
import fetch from 'isomorphic-fetch'

export function questions (exam, subject, year) {
  return fetch(`${apiUrl}/questions?et=${exam}&sb=${subject}&yr=${year}`, {
    method: 'GET'
  })
}

export function updateQuestion (question) {
  return fetch(`${apiUrl}/questions`, {
    method: 'PUT',
    headers: {
      'content-type': 'application/json',
      'accept': 'application/json'
    },
    body: JSON.stringify(question)
  })
}

export function postHistory (metaData) {
  return fetch(`${apiUrl}/history`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      'accept': 'application/json'
    },
    body: JSON.stringify(metaData)
  })
}

export function questionsByTopic (exam, subject, year, topic) {
  return fetch(`${apiUrl}/questions/topics/${topic}?et=${exam}&sb=${subject}&yr=${year}`, {
    method: 'GET'
  })
}
