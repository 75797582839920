import NotifierActions from '../actions/notifier-actions'

export function notifyError (message, title, duration) {
  NotifierActions.notify({
    type: types.ERROR,
    message: message,
    title: title,
    duration: duration
  })
}

export function notifySuccess (message, title, duration) {
  NotifierActions.notify({
    type: types.SUCCESS,
    message: message,
    title: title,
    duration: duration
  })
}

export function notifyInfo (message, title, duration) {
  NotifierActions.notify({
    type: types.INFO,
    message: message,
    title: title,
    duration: duration
  })
}

/**
 * Notification types.
 *
 * @type {{SUCCESS: string, INFO: string, ERROR: string}}
 */
export const types = {
  SUCCESS: 'success',
  INFO: 'info',
  ERROR: 'error'
}
