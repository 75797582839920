/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */


import Reflux from 'reflux'

import { getHistory } from '../api/history-api'
import { me } from '../api/login-api'

const HistoryActions = Reflux.createActions({
  
  retrieveHistory: { children: ['completed', 'failed'] },
  userProfile: {children: ['completed', 'failed']},
  });

HistoryActions.retrieveHistory = Reflux.createAction({asyncResult: true})

HistoryActions.retrieveHistory.listen(function (email) {
    
  return getHistory(email)
    .then(this.completed)
    .catch(this.failed)
}),
        
HistoryActions.userProfile.listen(function () {
  return me()
    .then(this.completed)
    .catch(this.failed)
})

export default HistoryActions
