import React from 'react'
import classNames from 'classnames'

import QuestionActions from '../../actions/question-actions'
import '../../../css/question-information.css!'

const QuestionInformation = React.createClass({

  propTypes: {
    information: React.PropTypes.string
  },

  getInitialState: function () {
    return {
      hidden: false
    }
  },

  contextTypes: {
    editMode: React.PropTypes.bool.isRequired,
    editableText: React.PropTypes.func.isRequired
  },

  showInformation: function () {
    this.setState({hidden: false})
  },

  hideInformation: function () {
    this.setState({hidden: true})
  },

  handleInformationEdit: function (editedInformation) {
    QuestionActions.setEditedInformation(editedInformation)
  },

  renderHTML: function () {
    return {__html: this.props.information}
  },

  render: function () {
    let classString = classNames({
      'view-information-overlay': true,
      'hidden': this.state.hidden
    })
    const information = this.props.information
    const editableInformation = this.context.editableText(this.handleInformationEdit)
    return (
      <div className='view-information'>
        <div className={classString}>
          <button className='close' onClick={this.hideInformation}>Close Information</button>
          {
            this.context.editMode
            ? <div className='content'>{editableInformation(information)}</div>
            : <div className='content' dangerouslySetInnerHTML={this.renderHTML()}/>
          }
        </div>
      </div>
    )
  }

})

export default QuestionInformation
