import React from 'react'
import '../../../css/login-loader.css!'

var LoginLoader = React.createClass({
  render: function () {
    return (
      <div className='spinner'>
				<div className='bounce1'></div>
				<div className='bounce2'></div>
				<div className='bounce3'></div>
			</div>
    )
  }
})

export default LoginLoader
