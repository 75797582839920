import Reflux from 'reflux'

import LoginActions from '../actions/login-actions'
import { notifySuccess, notifyError, notifyInfo } from '../util/notifier-utils'
import {
  SIGNUP_FAILED,
  SIGNUP_FAILED_MESSAGE,
  SIGNUP_SUCCESS,
  LOGIN_FAILED,
  LOGIN_FAILED_FACEBOOK,
  LOGIN_FAILED_GOOGLE,
  LOGIN_SUCCESS,
  LOGOUT_FAILED,
  LOGIN_FAILED_MESSAGE,
  LOGOUT_SUCCESS,
  USER_PROFILE_FAILED,
  FACEBOOK_UNAUTHORIZED,
  FACEBOOK_NOT_LOGGED_IN,
  GOOGLE_SIGNIN_FAILED,
  PRO_TIP,
  PRO_TIP_PROGRESS_TRACKING
} from '../util/messages'

const facebookStatuses = {
  CONNECTED: 'connected',
  UNAUTHORIZED: 'not_authorized'
}

const LoginStore = Reflux.createStore({
  listenables: [LoginActions],

  _loggedIn: false,

  _newlyRegistered: false,

  onLoginCompleted: function (response) {
    if (response.ok) {
      this._loggedIn = true
      this.trigger({loggedIn: true})
      notifySuccess(LOGIN_SUCCESS)
    } else {
      notifyError(LOGIN_FAILED_MESSAGE, LOGIN_FAILED)
    }
    this.trigger({inProgress: false})
    this._newlyRegistered = false
  },

  onLoginFailed: function () {
    notifyError(LOGIN_FAILED)
    this.trigger({inProgress: false})
    this._newlyRegistered = false
  },

  onLoginProgressed: function () {
    this.trigger({inProgress: true})
  },

  onRegisterCompleted: function () {
    /**
     * TODO:
     *  - We need a better way to find out if a user was successfully registered.
     *    The Stormpath servlet plugin returns a view similar to reset password requests.
     *    See `onForgotPassword` as well.
     */
    this._newlyRegistered = true
    this.trigger({newlyRegistered: true, inProgress: false})
  },

  onRegisterProgressed: function () {
    this.trigger({inProgress: true})
  },

  onUserProfileCompleted: function (response) {
    if (response.ok) {
      response.json().then((profile) => {
        this.trigger(profile)
        if (!profile.loggedIn) {
          if (this._newlyRegistered) {
            notifyError(SIGNUP_FAILED_MESSAGE, SIGNUP_FAILED)
          } else {
            notifyInfo(PRO_TIP_PROGRESS_TRACKING, PRO_TIP, 10000)
          }
        } else {
          if (this._newlyRegistered) {
            notifySuccess(SIGNUP_SUCCESS)
            this._newlyRegistered = false
          }
        }
      })
    } else {
      notifyError(USER_PROFILE_FAILED)
    }
  },

  onUserProfileFailed: function () {
    notifyError(USER_PROFILE_FAILED)
  },

  onLogoutCompleted: function (response) {
    if (response.ok) {
      this._loggedIn = false
      this.trigger({loggedIn: false})
      notifySuccess(LOGOUT_SUCCESS)
    } else {
      notifyError(LOGOUT_FAILED)
    }
  },

  onLogoutFailed: function () {
    notifyError(LOGOUT_FAILED)
  },

  onFacebookLoginCompleted: function (response) {
    this.onSocialLoginCompleted(response, LOGIN_FAILED_FACEBOOK)
  },

  onFacebookLoginFailed: function () {
    notifyError(LOGIN_FAILED_FACEBOOK, LOGIN_FAILED)
  },

  onFacebookStatusLoginCompleted: function (response) {
    this.handleFacebookResponse(response,
      LoginActions.facebookDirectLogin,
      LoginActions.facebookDirectLogin)
  },

  onFacebookDirectLoginCompleted: function (response) {
    this.handleFacebookResponse(response,
      notifyError.bind(null, FACEBOOK_UNAUTHORIZED),
      notifyError.bind(null, FACEBOOK_NOT_LOGGED_IN))
    this.trigger({inProgress: false})
  },

  handleFacebookResponse: function (response, unauthorizedFn, notLoggedInFn) {
    if (response.status && response.status.toLowerCase() === facebookStatuses.CONNECTED) {
      let credentials = {
        accessToken: response.authResponse.accessToken
      }
      LoginActions.facebookLogin(credentials)
    } else if (response.status.toLowerCase() === facebookStatuses.UNAUTHORIZED) {
      unauthorizedFn()
    } else {
      notLoggedInFn()
    }
  },

  onFacebookDirectLoginProgressed: function () {
    this.trigger({inProgress: true})
  },

  onGoogleLoginCompleted: function (response) {
    this.onSocialLoginCompleted(response, LOGIN_FAILED_GOOGLE)
  },

  onGoogleLoginFailed: function () {
    notifyError(LOGIN_FAILED_GOOGLE, LOGIN_FAILED)
  },

  onGoogleDirectLoginCompleted: function (authResult) {
    if (authResult && !authResult.error) {
      let credentials = {
        accessToken: authResult.access_token
      }
      LoginActions.googleLogin(credentials)
    } else {
      notifyError(GOOGLE_SIGNIN_FAILED)
    }
    this.trigger({inProgress: false})
  },

  onGoogleDirectLoginProgressed: function () {
    this.trigger({inProgress: true})
  },

  onSocialLoginCompleted: function (response, errorMessage) {
    if (response.ok) {
      response.json().then((profile) => {
        this.trigger(profile)
        if (profile.loggedIn) {
          notifySuccess(LOGIN_SUCCESS)
        } else {
          notifyError(errorMessage, LOGIN_FAILED)
        }
      })
    } else {
      notifyError(errorMessage, LOGIN_FAILED)
    }
  },

  onForgotPasswordCompleted: function () {
    /**
     * TODO:
     *  - This implementation is horrible because the current endpoint (supplied by stormpath servlet plugin)
     *    returns a path to a view that should be server render when the endpoint called.
     *    This obviously doesn't work because our client is not server rendered.
     *  - We need a response from the endpoint that let's us determine whether the reset password request succeeded or failed.
     */
    this.trigger({isRequestSent: true})
  },

  loggedIn: function () {
    return this._loggedIn
  }

})

export default LoginStore
