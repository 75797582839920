/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */


import { apiUrl } from './config'
import fetch from 'isomorphic-fetch'

export function getHistory (email) {
    
  return fetch(`${apiUrl}/history/`+email, {
    method: 'GET'
  })
}

export function postHistory (metaData) {
    console.log("metaData:"+metaData)
  return fetch(`${apiUrl}/history/`+metaData, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      'accept': 'application/json'
    },
    body: JSON.stringify(metaData)
  })
}
