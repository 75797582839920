import React from 'react'
import Slider from 'nuka-carousel'

import QuestionNumber from './question-number'
import QuestionActions from '../../actions/question-actions'

import '../../../css/question-numbers.css!'

var QuestionNumberGroup = React.createClass({
  mixins: [Slider.ControllerMixin],

  propTypes: {
    questions: React.PropTypes.array,
    currentSlide: React.PropTypes.number,
    questionsMetadata: React.PropTypes.array
  },
  componentWillReceiveProps: function (nextProps) {
    this.updateSliderPosition(this.state.carousels.slider, nextProps.currentSlide)
  },
  updateSliderPosition: function (slider, currentSlide) {
    let sliderPositionOffset = 4
    slider.goToSlide(currentSlide - sliderPositionOffset)
  },
  render: function () {
    return (
      <div className='question-number'>
        <Slider
          ref='slider'
          slidesToShow={10}
          slidesToScroll={5}
          decorators={[]}
          cellAlign={'left'}
          data={this.setCarouselData.bind(this, 'slider')}
          >
          {
            this.props.questions.map(
              function (question, i) {
                let classString = ''
                if (this.props.currentSlide === i) {
                  classString += 'selected'
                }
                if (this.props.questionsMetadata[i].isAnswered) {
                  classString += ' answered'
                }
                if (this.props.questionsMetadata[i].isBookmarked) {
                  classString += ' bookmarked'
                }

                return (<QuestionNumber key={i}
                                       slideTo={QuestionActions.gotoQuestion.bind(this, i)}
                                       number={i + 1}
                                       style={classString}/>)
              }.bind(this)
            )
          }
        </Slider>
        {
          this.state.carousels.slider
          ? <button type='button' className='prev-numbers' onClick={this.state.carousels.slider.previousSlide}>
              <span className='ion-arrow-left-b'></span>
            </button>
          : null
        }
        {
          this.state.carousels.slider
          ? <button type='button' className='next-numbers' onClick={this.state.carousels.slider.nextSlide}>
              <span className='ion-arrow-right-b'></span>
            </button>
          : null
        }
      </div>
    )
  }
})

export default QuestionNumberGroup
