import { apiUrl } from './config'
import fetch from 'isomorphic-fetch'

const defaultHeaders = {
  method: 'post',
  credentials: 'include',
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
  }
}

/**
 * Log in user by making request to token authentication endpoint.
 * Transforms credentials parameter into body with parameters required by Stormpath.
 *
 * @param credentials
 */
export function login (credentials) {
  return fetch(`${apiUrl}/oauth/token`, {
    ...defaultHeaders,
    body: `grant_type=password&username=${credentials.username}&password=${credentials.password}`
  })
}

/**
 * Send password reset to supplied email.
 */
export function forgot (email) {
  return fetch(`${apiUrl}/forgot`, {
    method: 'post',
    mode: 'no-cors',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    body: `email=${email}`
  })
}

/**
 * Log out currently logged in user.
 */
export function logout () {
  return fetch(`${apiUrl}/logout`, {
    credentials: 'include'
  })
}

/**
 * Register user by making request to registration endpoint.
 * Transforms credentials parameter into body with parameters required by Stormpath.
 *
 * @param credentials
 */
export function register (credentials) {
  return fetch(`${apiUrl}/register`, {
    ...defaultHeaders,
    body: `givenName=${credentials.givenName}&surname=${credentials.surname}&email=${credentials.email}&password=${credentials.password}&confirmPassword=${credentials.confirmPassword}`
  })
}

/**
 * Retrieve profile of currently logged in user.
 */
export function me () {
  return fetch(`${apiUrl}/me`, {
    credentials: 'include'
  })
}

/**
 * Log in user by sending their Facebook access token in exchange for a JWT token.
 *
 * @param accessToken Facebook account access token.
 */
export function facebookLogin ({accessToken}) {
  return fetch(`${apiUrl}/login/facebook`, {
    ...defaultHeaders,
    body: `accessToken=${accessToken}`
  })
}

/**
 * Log in user by sending their Google account access token in exchange for a JWT token.
 *
 * @param accessToken Google account access token.
 */
export function googleLogin ({accessToken}) {
  return fetch(`${apiUrl}/login/google`, {
    ...defaultHeaders,
    body: `accessToken=${accessToken}`
  })
}
