import React from 'react'
import RC2 from 'react-chartjs2'



class Bar extends React.Component {
  render() {
 


    return (
      <RC2 className="bar-chart" data={this.props.data}  options={this.props.options} type='bar' />
    );
  }
}

export default Bar;