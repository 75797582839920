/* */ 
var cloneBuffer = require("./cloneBuffer"),
    cloneMap = require("./cloneMap"),
    cloneRegExp = require("./cloneRegExp"),
    cloneSet = require("./cloneSet"),
    cloneSymbol = require("./cloneSymbol"),
    cloneTypedArray = require("./cloneTypedArray");
var boolTag = '[object Boolean]',
    dateTag = '[object Date]',
    mapTag = '[object Map]',
    numberTag = '[object Number]',
    regexpTag = '[object RegExp]',
    setTag = '[object Set]',
    stringTag = '[object String]',
    symbolTag = '[object Symbol]';
var arrayBufferTag = '[object ArrayBuffer]',
    float32Tag = '[object Float32Array]',
    float64Tag = '[object Float64Array]',
    int8Tag = '[object Int8Array]',
    int16Tag = '[object Int16Array]',
    int32Tag = '[object Int32Array]',
    uint8Tag = '[object Uint8Array]',
    uint8ClampedTag = '[object Uint8ClampedArray]',
    uint16Tag = '[object Uint16Array]',
    uint32Tag = '[object Uint32Array]';
function initCloneByTag(object, tag, isDeep) {
  var Ctor = object.constructor;
  switch (tag) {
    case arrayBufferTag:
      return cloneBuffer(object);
    case boolTag:
    case dateTag:
      return new Ctor(+object);
    case float32Tag:
    case float64Tag:
    case int8Tag:
    case int16Tag:
    case int32Tag:
    case uint8Tag:
    case uint8ClampedTag:
    case uint16Tag:
    case uint32Tag:
      return cloneTypedArray(object, isDeep);
    case mapTag:
      return cloneMap(object);
    case numberTag:
    case stringTag:
      return new Ctor(object);
    case regexpTag:
      return cloneRegExp(object);
    case setTag:
      return cloneSet(object);
    case symbolTag:
      return cloneSymbol(object);
  }
}
module.exports = initCloneByTag;
