import React from 'react'
import Reflux from 'reflux'
import classNames from 'classnames'
import { Link } from 'react-router'

import Year from './year'
import Topic from './topic'
import ExamActions from '../../actions/exam-actions'
import ExamStore from '../../stores/exam-store'
import { cdnImagesUrl } from '../../api/config'

const YearSelector = React.createClass({
  mixins: [Reflux.connect(ExamStore)],

  propTypes: {
    exams: React.PropTypes.array.isRequired,
    currentExamType: React.PropTypes.number.isRequired,
    currentSubject: React.PropTypes.number.isRequired
  },

  componentWillReceiveProps: function () {
    this.init()
  },

  init: function () {
    let exam = this.props.exams[this.props.currentExamType]
    let subject = exam.subjects[this.props.currentSubject]
    let examTitle = exam.title
    let subjectTitle = subject.title
    let fullExamTitle = `${examTitle} - ${subjectTitle}`
    let imagePath = `//${cdnImagesUrl}/${examTitle}.png`
    let yearOptions = this.getYearOptions(subject.years)

    this.setState({
      yearOptions: yearOptions,
      selectedYear: subject.years[0],
      imagePath: imagePath,
      fullExamTitle: fullExamTitle,
      subjectTitle: subjectTitle,
      examTitle: examTitle,
      selectedTopic: ''
    })
  },

  getYearOptions: function (years) {
    let sortedYears = years.sort(function (a, b) {
      return parseFloat(b) - parseFloat(a)
    })
    return sortedYears.map(function (year, key) {
      return (
        <Year year={year} key={key} />
      )
    })
  },

  getTopicOptions: function (topics) {
    return topics.map(function (topic, key) {
      return (
          <Topic id={topic.id} title={topic.title} key={key}/>
      )
    })
  },

  handleYearChange: function (event) {
    this.setState({selectedYear: event.target.value})
  },

  handleTopicChange: function (event) {
    this.setState({selectedTopic: event.target.value})
  },

  getBeginUrl: function() {
    let url = `/exam/${this.state.examTitle}/${this.state.subjectTitle}/${this.state.selectedYear}`
    if (this.state.selectedTopic) {
      url = url.concat(`/${this.state.selectedTopic}`)
    }

    return url
  },

  render: function () {
    let classString = classNames({
      'exam-year-overlay': true,
      'hidden': this.state.isYearSelectorHidden
    })

    return (
      <div className={classString} id='exam-year-overlay'>
        <div className='wrap clear'>
          <div className='photo'>
            <img src={this.state.imagePath}/>
          </div>
          <div className='text'>
            <h3>{this.state.fullExamTitle}</h3>
            <select value={this.state.selectedTopic} onChange={this.handleTopicChange} required>
              {this.getTopicOptions(this.state.currentSubjectTopics)}
            </select>
            <select value={this.state.selectedYear} onChange={this.handleYearChange} required>
              {this.state.yearOptions}
            </select>
            <Link to={this.getBeginUrl()} className='begin'>
              Begin
            </Link>
            <button onClick={ExamActions.hideYearSelector}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    )
  }
})

export default YearSelector
