import React from 'react'

/**
 * A countdown timer.
 */
class CountdownTimer extends React.Component {

  /**
   * propTypes
   * @property {number} initialTimeRemaining The time remaining for the countdown (in ms).
   * @property {number} [interval=1000] The time between timer ticks (in ms).
   * @property {function} [formatFn(timeRemaining)] A function that formats the timeRemaining.
   * @property {function} [tickCallback(timeRemaining)] A function to call on each tick.
   */
  static get propTypes () {
    return {
      initialTimeRemaining: React.PropTypes.number,
      interval: React.PropTypes.number,
      formatFn: React.PropTypes.func,
      tickCallback: React.PropTypes.func,
      completeCallback: React.PropTypes.func
    }
  }

  static get defaultProps () {
    return {
      interval: 1000,
      initialTimeRemaining: 10000
    }
  }

  constructor (props) {
    super(props)
    this.state = {
      timeRemaining: this.props.initialTimeRemaining
    }
    this.tick = this.tick.bind(this)
  }

  componentDidMount () {
    this.tick()
  }

  componentDidUpdate () {
    if ((!this.state.prevTime) && this.state.timeRemaining > 0) {
      this.tick()
    }
  }

  componentWillUnmount () {
    clearTimeout(this.state.timeoutId)
  }

  tick () {
    let currentTime = Date.now()
    let deviation = currentTime - this.state.prevTime || 0
    let {interval} = this.props
    let timeRemainingInInterval = (interval - (deviation % interval))
    let timeout = timeRemainingInInterval

    if (timeRemainingInInterval < (interval / 2.0)) {
      timeout += interval
    }

    let timeRemaining = Math.max(this.state.timeRemaining - deviation, 0)
    let cautionRange = (timeRemaining <= this.props.initialTimeRemaining * 0.40) && (timeRemaining > this.props.initialTimeRemaining * 0.20)
    let warningRange = (timeRemaining <= this.props.initialTimeRemaining * 0.20) && (timeRemaining >= 0)
    let countdownComplete = this.state.prevTime && timeRemaining <= 0
    let color = ''
    if (this.state.timeoutId) {
      clearTimeout(this.state.timeoutId)
    }
    this.setState({
      timeoutId: countdownComplete ? undefined : setTimeout(this.tick, timeout),
      prevTime: currentTime,
      timeRemaining: timeRemaining,
      color: color
    })

    if (cautionRange) {
      this.setState({color: 'orange'})
    }

    if (warningRange) {
      this.setState({color: 'red'})
    }

    if (countdownComplete) {
      if (this.props.completeCallback) {
        this.props.completeCallback()
      }
      return
    }

    if (this.props.tickCallback) {
      this.props.tickCallback(timeRemaining)
    }
  }

  getFormattedTime (milliseconds) {
    if (this.props.formatFn) {
      return this.props.formatFn(milliseconds)
    }

    let totalSeconds = Math.round(milliseconds / 1000)

    let seconds = parseInt(totalSeconds % 60, 10)
    let minutes = parseInt(totalSeconds / 60, 10) % 60
    let hours = parseInt(totalSeconds / 3600, 10)

    seconds = seconds < 10 ? `0${seconds}` : seconds
    minutes = minutes < 10 ? `0${minutes}` : minutes
    hours = hours < 10 ? `0${hours}` : hours

    return `${hours}:${minutes}:${seconds}`
  }

  render () {
    return (
      <p className={this.state.color}>
        {this.getFormattedTime(this.state.timeRemaining)}
      </p>
    )
  }
}

export default CountdownTimer
