import Reflux from 'reflux'

import NotifierActions from '../actions/notifier-actions'

const NotifierStore = Reflux.createStore({
  listenables: [NotifierActions],

  onNotify: function (notification) {
    this.trigger(notification)
  }

})

export default NotifierStore
