import Reflux from 'reflux'

import { questions, questionsByTopic } from '../api/question-api'
import { updateQuestion } from '../api/question-api'
import { postHistory } from '../api/question-api'

const QuestionActions = Reflux.createActions({
  nextQuestion: {},
  prevQuestion: {},
  gotoQuestion: {},
  selectAnswer: {},
  bookmarkQuestion: {},
  finishExam: {children: ['completed', 'failed']},
  retrieveQuestions: {children: ['completed', 'failed']},
  setEditedQuestionText: {},
  setEditedImage: {},
  setEditedChoice: {},
  setEditedInformation: {},
  updateTopic:{},
  updateQuestion: {children: ['completed', 'failed']}
})

QuestionActions.retrieveQuestions.listen(function (exam, subject, year, topic) {
  let request;
  if (topic) {
    request = questionsByTopic (exam, subject, year, topic)
  } else {
    request = questions(exam, subject, year)
  }

  return request
      .then(this.completed)
      .catch(this.failed)
})

QuestionActions.updateQuestion.listen(function (question) {
    return updateQuestion(question)
      .then(this.completed)
      .catch(this.failed)
})

QuestionActions.finishExam.listen(function (metaData) {
    return postHistory(metaData)
      .then(this.completed)
      .catch(this.failed)
})

export default QuestionActions
