import React from 'react'

import QuestionChoice from './question-choice'

const QuestionChoiceGroup = React.createClass({
  propTypes: {
    choices: React.PropTypes.array.isRequired,
    metadata: React.PropTypes.object.isRequired
  },

  render: function () {
    return (
      <ul>
        {
          this.props.choices.map((c, key) =>
            <QuestionChoice
              text={c.text}
              choiceIndex={key}
              metadata={this.props.metadata}
              key={key}
              />
          )
        }
      </ul>
    )
  }
})

export default QuestionChoiceGroup
