import React from 'react'

const LoginInfo = React.createClass({

  render: function () {
    return (
      <div className='text'>
          <div className='text-row'>
            <div className='icon-container'>
              <span className='icon ion-ios-play'></span>
            </div>
            <div>
              <h3>Practice at Your Pace</h3>
              Practice questions from different subjects
            </div>
          </div>
          <div className='text-row'>
            <div className='icon-container'>
              <span className='icon ion-plane'></span>
            </div>
            <div>
              <h3>Take it Everywhere</h3>
              Easily accessible on PCs, Tablets and Mobile Devices
            </div>
          </div>
        </div>
      )
  }
})

export default LoginInfo
