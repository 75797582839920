/* global confirm */
import React from 'react'
import Reflux from 'reflux'
import $ from 'jquery'
import Notifier from '../toolbox/notifier'
import CountdownTimer from '../toolbox/countdown-timer'
import ExamActions from '../../actions/exam-actions'
import QuestionActions from '../../actions/question-actions'
import QuestionStore from '../../stores/question-store'
import LoginStore from '../../stores/login-store'
import NotifierStore from '../../stores/notifier-store'
import { cdnImagesUrl } from '../../api/config'

const ExamHeader = React.createClass({
    duration:0,
    mixins: [Reflux.connect(QuestionStore), Reflux.connect(LoginStore)],

    propTypes: {
        exam: React.PropTypes.string,
        subject: React.PropTypes.string,
        year: React.PropTypes.string
    },

    contextTypes: {
        editMode: React.PropTypes.bool.isRequired
    },

    componentDidMount: function () {
        this.listenTo(NotifierStore, this.receiveNotification)
    },

    receiveNotification: function (notification) {
        Notifier.receiveNotification(this.refs.notifier, notification)
    },

    confirmFinish: function () {
      
        const documentBody = $('body');
        const blur = 'blur';
        var today = new Date()+"";
        documentBody.addClass(blur);
        const promptResponse = confirm('You are about to submit your exam! Are you sure?');
        if (promptResponse === true) {
            this.duration = parseInt(3600000 - (this.refs.time.state.timeRemaining));        
            var examHistory={
                userId: this.state.email,
                exam: this.props.exam,
                subject: this.props.subject,
                year: parseInt(this.props.year),
                duration: this.duration,
                finished :true,
                date: today.split(' ', 5).join(' '),
                questions:this.state.questionsHistoryMetadata,
                latitude:'',
                longitude:''                    
            };

            documentBody.removeClass(blur);
            QuestionActions.finishExam(examHistory);
        } 
        else {
            documentBody.removeClass(blur);
        }
    },
    finishByTime: function () {
        var today = new Date()+"";
        this.duration = parseInt(3600000 - (this.refs.time.state.timeRemaining));
        var examHistory={
            userid: this.state.email,
            exam: this.props.exam,
            subject: this.props.subject,
            year: parseInt(this.props.year),
            duration: this.duration,
            finished :false,
            date:  today.split(' ', 5).join(' '),
            questions:this.state.questionsHistoryMetadata,
            latitude:'',
            longitude:''                        
        };
        QuestionActions.finishExam(examHistory);
    },

    render: function () {
            let fullExamTitle = `${this.props.exam} | ${this.props.subject} | ${this.props.year}`
            let imagePath = `//${cdnImagesUrl}/${this.props.exam}.png`

     return (
      <header>
        <Notifier ref='notifier'/>
        <div className='wrap clear'>
          <div className='photo'>
            <img src={imagePath} alt='logo.png'></img>
          </div>
          <div className='title'>
            {fullExamTitle}
          </div>
          {
            this.context.editMode
              ? <div>
              <button className='finish-button' onClick={ExamActions.cancelEdit}>Cancel Edit</button>
              <button className='finish-button' onClick={QuestionActions.updateQuestion.bind(null, this.state.currentQuestion)}>Update Question</button>
            </div>
              : <div>
              {
                this.state.loggedIn && this.state.isEditor
                  ? <button className='finish-button' onClick={ExamActions.editExam}>Edit</button>
                  : ''
              }
              <button className='finish-button' onClick={this.confirmFinish}>Finish Exam</button>
            </div>
          }
          {
            this.state.questions.length > 0
              ? <div className='timer'>
                <CountdownTimer ref='time' initialTimeRemaining={3600000} completeCallback={this.finishByTime}/>
              </div>
              : ''
          }
        </div>
      </header>
    )
  }
})

export default ExamHeader
