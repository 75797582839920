import React from 'react'

import ExamActions from '../../actions/exam-actions'

const ExamType = React.createClass({
  propTypes: {
    index: React.PropTypes.number,
    title: React.PropTypes.string
  },

  onCurrentExamChange: function () {
    ExamActions.changeCurrentExam(this.props.index)
  },

  render: function () {
    return (
      <li onMouseOver={this.onCurrentExamChange}>
        <span>{this.props.title}</span>
      </li>
    )
  }

})

export default ExamType
