/* */ 
var LazyWrapper = require("./LazyWrapper"),
    LodashWrapper = require("./LodashWrapper"),
    copyArray = require("./copyArray");
function wrapperClone(wrapper) {
  if (wrapper instanceof LazyWrapper) {
    return wrapper.clone();
  }
  var result = new LodashWrapper(wrapper.__wrapped__, wrapper.__chain__);
  result.__actions__ = copyArray(wrapper.__actions__);
  result.__index__ = wrapper.__index__;
  result.__values__ = wrapper.__values__;
  return result;
}
module.exports = wrapperClone;
