/* */ 
(function(process) {
  'use strict';
  var DOMChildrenOperations = require("./DOMChildrenOperations");
  var DOMLazyTree = require("./DOMLazyTree");
  var DOMPropertyOperations = require("./DOMPropertyOperations");
  var ReactDOMComponentTree = require("./ReactDOMComponentTree");
  var ReactPerf = require("./ReactPerf");
  var assign = require("./Object.assign");
  var escapeTextContentForBrowser = require("./escapeTextContentForBrowser");
  var validateDOMNesting = require("./validateDOMNesting");
  var getNode = ReactDOMComponentTree.getNodeFromInstance;
  var ReactDOMTextComponent = function(props) {};
  assign(ReactDOMTextComponent.prototype, {
    construct: function(text) {
      this._currentElement = text;
      this._stringText = '' + text;
      this._nativeNode = null;
      this._nativeParent = null;
      this._domID = null;
      this._mountIndex = 0;
    },
    mountComponent: function(transaction, nativeParent, nativeContainerInfo, context) {
      if (process.env.NODE_ENV !== 'production') {
        var parentInfo;
        if (nativeParent != null) {
          parentInfo = nativeParent._ancestorInfo;
        } else if (nativeContainerInfo != null) {
          parentInfo = nativeContainerInfo._ancestorInfo;
        }
        if (parentInfo) {
          validateDOMNesting('span', this, parentInfo);
        }
      }
      var domID = nativeContainerInfo._idCounter++;
      this._domID = domID;
      this._nativeParent = nativeParent;
      if (transaction.useCreateElement) {
        var ownerDocument = nativeContainerInfo._ownerDocument;
        var el = ownerDocument.createElement('span');
        ReactDOMComponentTree.precacheNode(this, el);
        var lazyTree = DOMLazyTree(el);
        DOMLazyTree.queueText(lazyTree, this._stringText);
        return lazyTree;
      } else {
        var escapedText = escapeTextContentForBrowser(this._stringText);
        if (transaction.renderToStaticMarkup) {
          return escapedText;
        }
        return '<span ' + DOMPropertyOperations.createMarkupForID(domID) + '>' + escapedText + '</span>';
      }
    },
    receiveComponent: function(nextText, transaction) {
      if (nextText !== this._currentElement) {
        this._currentElement = nextText;
        var nextStringText = '' + nextText;
        if (nextStringText !== this._stringText) {
          this._stringText = nextStringText;
          DOMChildrenOperations.updateTextContent(getNode(this), nextStringText);
        }
      }
    },
    getNativeNode: function() {
      return getNode(this);
    },
    unmountComponent: function() {
      ReactDOMComponentTree.uncacheNode(this);
    }
  });
  ReactPerf.measureMethods(ReactDOMTextComponent.prototype, 'ReactDOMTextComponent', {
    mountComponent: 'mountComponent',
    receiveComponent: 'receiveComponent'
  });
  module.exports = ReactDOMTextComponent;
})(require("process"));
