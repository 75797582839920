/* global localStorage */
import React from 'react'
import Reflux from 'reflux'
import ReactMixin from 'react-mixin'
import bindAll from 'lodash/bindAll'

import SubjectSelector from '../subject/subject-selector'
import YearSelector from '../year/year-selector'
import ExamSelector from './exam-selector'
import PageHeader from '../page/page-header'
import Onboarding from '../onboarding/onboarding'
import ExamStore from '../../stores/exam-store'
import ExamActions from '../../actions/exam-actions'
import Loader from '../toolbox/loader'

@ReactMixin.decorate(Reflux.connect(ExamStore))
class ExamSelection extends React.Component {

  constructor (props) {
    super(props)
    bindAll(this)
  }

  componentWillMount () {
    if (localStorage.onboarded) {
      this.setState({isOnboardingHidden: true})
    }
    ExamActions.retrieveExams()
  }

  getExamColor (type) {
    let color
    switch (type) {
      case 0:
        color = 'blue'
        break
      case 1:
        color = 'orange'
        break
      default:
        color = 'green'
    }
    return color
  }

  render () {
    let colorString = `subjects ${this.getExamColor(this.state.currentExamType)}`
    return (
      <div>
        <PageHeader/>
        {
          this.state.exams.length > 0
          ? <div className='exam-selection'>
              <ExamSelector exams={this.state.exams}/>
              <SubjectSelector
                exams={this.state.exams}
                currentExamType={this.state.currentExamType}
                className={colorString}
                />
              <YearSelector
                exams={this.state.exams}
                currentExamType={this.state.currentExamType}
                currentSubject={this.state.currentSubject}
                />
          </div>
          : <Loader/>
        }
        {
          !this.state.isOnboardingHidden ? <Onboarding/> : null
        }
      </div>
      )
  }
}

export default ExamSelection
