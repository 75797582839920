import { apiUrl } from './config'
import fetch from 'isomorphic-fetch'

export function subjectTopics (subject) {
  return fetch(`${apiUrl}/topics/${subject}`, {
    method: 'GET'
  })
}

export function addNewTopic (topic) {
  return fetch(`${apiUrl}/topics`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      'accept': 'application/json'
    },
    body: JSON.stringify(topic)
  })
}