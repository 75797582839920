/* global MathJax*/
import React from 'react'
import Reflux from 'reflux'

import QuestionActions from '../../actions/question-actions'
import QuestionChoiceGroup from './question-choice-group'
import QuestionInformation from './question-information'
import ExamActions from '../../actions/exam-actions'
import Topic from '../year/topic'
import ExamStore from '../../stores/exam-store'
import QuestionStore from '../../stores/question-store'
import QuestionBookmark from './question-bookmark'
import ReportQuestion from './report-question'
import '../../../css/question-and-answers.css!'

const Question = React.createClass({
    mixins: [Reflux.connect(ExamStore),Reflux.connect(QuestionStore)],

  propTypes: {
    index: React.PropTypes.number.isRequired,
    question: React.PropTypes.object.isRequired,
    metadata: React.PropTypes.object.isRequired
  },

  contextTypes: {
    editMode: React.PropTypes.bool.isRequired,
    editableText: React.PropTypes.func.isRequired
  },
  componentWillMount: function () {
       this.setState({
     newTopic: '',
     selectedTopic:''
    })
  },
  componentDidMount: function () {
    if (!this.context.editMode) {
      MathJax.Hub.Queue(['Typeset', MathJax.Hub])
    }
    ExamActions.retrieveSubjectTopics(this.props.question.subject)
        if(this.state.currentQuestion.topic != null ){ 
        this.setState({selectedTopic: this.state.currentQuestion.topic})
    }
  },

  componentDidUpdate: function () {
    if (!this.context.editMode) {
      MathJax.Hub.Queue(['Typeset', MathJax.Hub])
    }
  },

  handleQuestionTextEdit: function (editedQuestionText) {
    QuestionActions.setEditedQuestionText(editedQuestionText)
  },

  handleImageEdit: function (editedImage) {
    QuestionActions.setEditedImage(editedImage)
  },
  handleTopicChange: function (event) {
    this.setState({selectedTopic: event.target.value})
  },
  updateTopic: function (event) {
      QuestionActions.updateTopic(this.state.selectedTopic)
  },
  addNewTopic: function (event) {
      let topic = {
          title:this.state.newTopic,
          subject:this.props.question.subject
      }
      ExamActions.addNewTopic(topic)

  },
  
  handleChange(event) {
    this.setState({newTopic: event.target.value});
  },

  getTopicOptions: function (topics) {
    return topics.map(function (topic, key) {
      return (
          <Topic id={topic.id} title={topic.title} key={key}/>
      )
    })
  },

  renderHTML: function () {
    return {__html: this.props.question.text}
  },

  render: function () {
    let metadata = this.props.metadata
    let base64String = this.props.question.image
    let information = this.props.question.information
    const editableQuestionText = this.context.editableText(this.handleQuestionTextEdit)
    const editableImage = this.context.editableText(this.handleImageEdit)  
    return (
      <div>
        <div className='question'>
          {
            information ? <QuestionInformation information={information}/> : ''
          }
          <span className='index'>{this.props.index + 1}.</span>
          {
            this.context.editMode
            ? <span>{editableQuestionText(this.props.question.text)}</span> : <span dangerouslySetInnerHTML={this.renderHTML()}/>
          }
          <div className='question-image'>
            {
              base64String
              ? this.context.editMode
                ? <span>{editableImage(base64String)}</span> : <img src={'data:image/png;base64,' + base64String}/>
              : ''
            }
          </div>
        </div>
        <div className='clear'>
          <QuestionBookmark metadata={metadata}/>
        </div>
        <div className='answers'>
          <QuestionChoiceGroup choices={this.props.question.options} metadata={metadata}/>
        </div>
        <button className='prev' onClick={QuestionActions.prevQuestion}>
          <span className='ion-ios-arrow-left'></span>
          <span className='prev-title'>Previous</span>
        </button>
        <button className='next' onClick={QuestionActions.nextQuestion}>
          <span className='ion-ios-arrow-right'></span>
          <span className='next-title'>Next</span>
        </button>        
        {
        
            
            this.context.editMode
                ? <div>
                    <select value={this.state.selectedTopic} onChange={this.handleTopicChange} required>
                        {this.getTopicOptions(this.state.currentSubjectTopics)}
                    </select>
                    <button className='add-topic' onClick={this.updateTopic}>Add Topic</button>
                    
                      <div>
                      <input className='input-new-topic' type="text" value ={this.state.newTopic} onChange={this.handleChange}/>
                      <button className='add-new-topic' onClick={this.addNewTopic}>Add New Topic</button>
                     </div>
                  </div>
                  
                  
              : ''
        }
      </div>
    )
  }
})

export default Question
