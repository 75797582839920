/* */ 
var _ = require("./utils");
module.exports = function mix(def) {
  var composed = {
    init: [],
    preEmit: [],
    shouldEmit: []
  };
  var updated = (function mixDef(mixin) {
    var mixed = {};
    if (mixin.mixins) {
      mixin.mixins.forEach(function(subMixin) {
        _.extend(mixed, mixDef(subMixin));
      });
    }
    _.extend(mixed, mixin);
    Object.keys(composed).forEach(function(composable) {
      if (mixin.hasOwnProperty(composable)) {
        composed[composable].push(mixin[composable]);
      }
    });
    return mixed;
  }(def));
  if (composed.init.length > 1) {
    updated.init = function() {
      var args = arguments;
      composed.init.forEach(function(init) {
        init.apply(this, args);
      }, this);
    };
  }
  if (composed.preEmit.length > 1) {
    updated.preEmit = function() {
      return composed.preEmit.reduce(function(args, preEmit) {
        var newValue = preEmit.apply(this, args);
        return newValue === undefined ? args : [newValue];
      }.bind(this), arguments);
    };
  }
  if (composed.shouldEmit.length > 1) {
    updated.shouldEmit = function() {
      var args = arguments;
      return !composed.shouldEmit.some(function(shouldEmit) {
        return !shouldEmit.apply(this, args);
      }, this);
    };
  }
  Object.keys(composed).forEach(function(composable) {
    if (composed[composable].length === 1) {
      updated[composable] = composed[composable][0];
    }
  });
  return updated;
};
