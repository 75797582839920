/* */ 
var Reflux = require("./index"),
    _ = require("./utils");
module.exports = function(listenable, key, filterFunc) {
  filterFunc = _.isFunction(key) ? key : filterFunc;
  return {
    getInitialState: function() {
      if (!_.isFunction(listenable.getInitialState)) {
        return {};
      } else if (_.isFunction(key)) {
        return filterFunc.call(this, listenable.getInitialState());
      } else {
        var result = filterFunc.call(this, listenable.getInitialState());
        if (result) {
          return _.object([key], [result]);
        } else {
          return {};
        }
      }
    },
    componentDidMount: function() {
      _.extend(this, Reflux.ListenerMethods);
      var me = this;
      var cb = function(value) {
        if (_.isFunction(key)) {
          me.setState(filterFunc.call(me, value));
        } else {
          var result = filterFunc.call(me, value);
          me.setState(_.object([key], [result]));
        }
      };
      this.listenTo(listenable, cb);
    },
    componentWillUnmount: Reflux.ListenerMixin.componentWillUnmount
  };
};
