/* global MathJax, localStorage*/
import React from 'react'
import Reflux from 'reflux'
import ClassNames from 'classnames'
import PageHeader from '../page/page-header'
import QuestionStore from '../../stores/question-store'
import ReviewQuestion from './review-question'
import '../../../css/exam-review.css!'
import '../../../css/style.css!'
import '../../../css/ionicons.min.css!'

const ExamReview = React.createClass({
  mixins: [Reflux.connect(QuestionStore)],

  getInitialState: function () {
    return {
      isFeedbackOverlayHidden: true
    }
  },

  componentDidMount: function () {
    MathJax.Hub.Queue(['Typeset', MathJax.Hub])
  },

  componentDidUpdate: function () {
    MathJax.Hub.Queue(['Typeset', MathJax.Hub])
  },

  showFeedbackOverlay: function () {
    this.setState({isFeedbackOverlayHidden: false})
    localStorage.gaveFeedback = true
  },

  hideFeedbackOverlay: function () {
    this.setState({isFeedbackOverlayHidden: true})
  },

  retakeExam: function () {
    if (!localStorage.gaveFeedback) {
      this.showFeedbackOverlay()
    } else {
      window.history.back()
    }
  },

  takeAnotherExam: function () {
    if (!localStorage.gaveFeedback) {
      this.showFeedbackOverlay()
    } else {
      window.location.assign('/app')
    }
  },

  render: function () {
    let classString = ClassNames({
      'show-overlay': true,
      'hidden': this.state.isFeedbackOverlayHidden
    })
    return (
      <div>
        <PageHeader/>
        <div className='feedback-popout'>
          <a href='../feedback.html' className='clear' title='Please leave us a feedback' target='_blank'>
              <span className='icon ion-paper-airplane'></span>
              <span>Feedback</span>
          </a>
        </div>
        <div className='feedback-popout contact-popout'>
          <a href='http://flexisaf.com/contact-us/' className='clear' target='_blank'>
              <span className='icon ion-android-mail'></span>
              <span>Contact</span>
          </a>
        </div>
        <div className='intro-section'>
          <div className='overlay'>
            <div className='wrap'>
              <h1>Completed!</h1>
              <p>Score: {this.state.score} out of {this.state.questions.length}</p>
              <p>Attempted Questions: {this.state.attemptedQuestions}</p>
              <a onClick={this.retakeExam}>Retake Exam</a>
              <a onClick={this.takeAnotherExam}>Take Another Exam</a>
            </div>
          </div>
      </div>
      <div className='exam-review'>
        <h3>Questions Review</h3>
        {
          this.state.questions.map((question, key) =>
             <ReviewQuestion question = {question} questionMetadata = {this.state.questionsMetadata[key]} key= {key}/>
          )
        }
      </div>
      <div className={classString}>
        <div className='wrap'>
          We'd appreciate it if you took a minute to give us feedback on your experience using Distinction.ng
          <span className='icon ion-thumbsup'></span>
          <div className='feedback'>
            <p>Help us serve you better through your valuable feedback :)</p>
            <a href='../feedback.html' target='_blank' className='links'>Provide Feedback</a>
            <a onClick={this.hideFeedbackOverlay}>Close</a>
          </div>
        </div>
      </div>
    </div>
    )
  }
})

export default ExamReview
