/* */ 
var assocIndexOf = require("./assocIndexOf");
function assocSet(array, key, value) {
  var index = assocIndexOf(array, key);
  if (index < 0) {
    array.push([key, value]);
  } else {
    array[index][1] = value;
  }
}
module.exports = assocSet;
