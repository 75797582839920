import React from 'react'
import bindAll from 'lodash/bindAll'

import { types } from '../../util/notifier-utils'
import '../../../css/notifier.css!'

/**
 * A component that displays a notification.
 */
class Notifier extends React.Component {

  displayName = 'Notifier';

  key = 0;

  constructor (props) {
    super(props)
    bindAll(this)
    this.state = {}
  }

  /**
   * Takes a ref to a Notifier and displays a notification on it.
   *
   * @param notifierRef Reference to a Notifier.
   * @param type Type of the notification.
   * @param message Message that will be shown by the notification.
   * @param title Title of the notification.
   * @param duration Duration the notification will be shown for.
   */
  static receiveNotification (notifierRef, {type, message, title = '', duration = 3200}) {
    switch (type) {
      case types.SUCCESS:
        notifierRef.success(title, message, duration)
        break
      case types.INFO:
        notifierRef.info(title, message, duration)
        break
      case types.ERROR:
        notifierRef.error(title, message, duration)
        break
    }
  }

  /**
   * Shows a success notification.
   *
   * @param title Title of the notification.
   * @param msg Message that will be shown by the notification.
   * @param time Duration the notification will be shown for.
   */
  success (title, msg, time) {
    this.addNotify(title, msg, time, types.SUCCESS)
  }

  /**
   * Shows an error notification.
   *
   * @param title Title of the notification.
   * @param msg Message that will be shown by the notification.
   * @param time Duration the notification will be shown for.
   */
  error (title, msg, time) {
    this.addNotify(title, msg, time, types.ERROR)
  }

  /**
   * Shows an informational notification.
   *
   * @param title Title of the notification.
   * @param msg Message that will be shown by the notification.
   * @param time Duration the notification will be shown for.
   */
  info (title, msg, time) {
    this.addNotify(title, msg, time, types.INFO)
  }

  addNotify (title, msg, time, theme) {
    let key = this.key++
    this.state[key] = { title, msg, time, theme }
    this.setState(this.state)
    this.countToHide(time, key)
  }

  countToHide (duration, key) {
    setTimeout(() => {
      this.hideNotification(key)
    }, duration)
  }

  hideNotification (key) {
    delete this.state[key]
    this.setState(this.state)
  }

  render () {
    let keys = Object.keys(this.state)
    let state = this.state
    var hide = this.hideNotification.bind(this)
    let el = keys.map((key) => {
      return (
        <Item
          id={key}
          key={key}
          theme={state[key].theme}
          hideNotification={hide}
          title={state[key].title}
          msg={state[key].msg}
        />
      )
    })
    return <div className='notify-container'>{el}</div>
  }
}

class Item extends React.Component {

  displayName = 'Notifier Item';

  constructor (props) {
    super(props)
    bindAll(this)
  }

  static get propTypes () {
    return {
      id: React.PropTypes.string.isRequired,
      msg: React.PropTypes.string.isRequired,
      theme: React.PropTypes.string.isRequired,
      title: React.PropTypes.string.isRequired,
      hideNotification: React.PropTypes.func.isRequired
    }
  }

  hideNotification () {
    this.props.hideNotification(this.props.id)
  }

  render () {
    return (
      <div className={'notify-item ' + this.props.theme} onClick={this.hideNotification.bind(this)}>
        <p className='notify-title'>{this.props.title}</p>
        <p className='notify-body' dangerouslySetInnerHTML={{__html: this.props.msg}}/>
      </div>
    )
  }
}

export default Notifier
