import React from 'react'
import Reflux from 'reflux'
import QuestionStore from '../../stores/question-store'
import ClassNames from 'classnames'

const ExamResult = React.createClass({
  mixins: [Reflux.connect(QuestionStore)],
  propTypes: {
    exam: React.PropTypes.string.isRequired,
    subject: React.PropTypes.string.isRequired
  },

  reload: function () {
    window.location.reload(true)
  },

  twitterShareUrl: function (url, score, questions, exam, subject) {
    return `https://twitter.com/share?url=${url}&text=I just scored ${score} out of ${questions} practising for ${exam} - ${subject} on &via=DistinctionNg&hashtags=`
  },

  render: function () {
    let classString = ClassNames({
      'show-overlay': true,
      'hidden': this.state.resultOverlayHidden
    })
    return (
      <div className={classString}>
        <div className='wrap'>
          <h1>Completed!</h1>
          <p>You scored {this.state.score} out of {this.state.questions.length}</p>
          <p>You attempted {this.state.attemptedQuestions} questions</p>
          <a onClick={this.reload}>Retake Exam</a>
          <a href='/app'>Take Another Exam</a>
          <div className='feedback'>
            <p>See a Comprehensive Review of your Performance</p>
            <a href='/app/#/review' className='links'>Review your Exam</a>
          </div>
          <p className='social-share clear'>
            <span>Share on</span>
            <a href={this.twitterShareUrl('https://distinction.ng', this.state.score, this.state.questions.length, this.props.exam, this.props.subject)} target='_blank'>
              <span className='twitter-blue ion-social-twitter'></span>
            </a>
            <a href='http://www.facebook.com/sharer.php?u=www.distinction.ng' target='_blank'>
              <span className='facebook-blue ion-social-facebook'></span>
            </a>
            <a href='https://plus.google.com/share?url=www.distinction.ng' target='_blank'>
               <span className='googleplus-red ion-social-googleplus'></span>
            </a>
          </p>
          <p>
            <a href='/' className='homelink'>
              Return to Homepage
            </a>
          </p>
        </div>
      </div>
    )
  }
})

export default ExamResult
