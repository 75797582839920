import React from 'react'
import ReactDOM from 'react-dom'
import Reflux from 'reflux'
import bindAll from 'lodash/bindAll'
import ReactMixin from 'react-mixin'
import LinkedStateMixin from 'react-addons-linked-state-mixin'

import LoginPage from '../page/login-page'
import LoginActions from '../../actions/login-actions'
import LoginStore from '../../stores/login-store'
import LoginInfo from '../login/login-info'
import LoginLoader from '../toolbox/login-loader'
import { PASSWORD_MISMATCH } from '../../util/messages'

@ReactMixin.decorate(LinkedStateMixin)
@ReactMixin.decorate(Reflux.connect(LoginStore))
class RegistrationForm extends React.Component {

  constructor (props, context) {
    super(props, context)
    bindAll(this)
    this.state = {
      givenName: '',
      surname: '',
      username: '',
      password: '',
      confirmPassword: '',
      errorMessage: null,
      inProgress: false
    }
  }

  static get propTypes () {
    return {
      switch: React.PropTypes.func.isRequired,
      history: React.PropTypes.object.isRequired
    }
  }

  componentDidMount () {
    ReactDOM.findDOMNode(this.refs.givenName).focus()
  }

  componentWillUpdate (nextProps, nextState) {
    if (nextState.newlyRegistered) {
      this.props.history.pushState(null, '/')
    }
  }

  onSubmit (e) {
    e.preventDefault()
    const givenName = this.state.givenName
    const surname = this.state.surname
    const email = this.state.email
    const password = this.state.password
    const confirmPassword = this.state.confirmPassword

    LoginActions.register({
      givenName,
      surname,
      email,
      password,
      confirmPassword
    })
  }

  onGivenNameChanged (e) {
    this.state.givenName = e.target.value
  }

  onSurnameChanged (e) {
    this.state.surname = e.target.value
  }

  onEmailChanged (e) {
    this.state.email = e.target.value
  }

  onPasswordChanged (e) {
    this.state.password = e.target.value
  }

  onConfirmPasswordChanged (e) {
    this.state.confirmPassword = e.target.value

    if (this.state.password !== this.state.confirmPassword) {
      this.setState({errorMessage: PASSWORD_MISMATCH})
    } else {
      this.setState({errorMessage: null})
    }
  }

  render () {
    const switchToLoginForm = this.props.switch.bind(null, LoginPage.formTypes.LOGIN)
    return (
      <div>
        {
          this.state.inProgress ? <LoginLoader/> : ''
        }
        <form className='login' onSubmit={this.onSubmit.bind(this)}>
          <div className='login-fields'>
            <input
              type='text'
              ref='givenName'
              className='field'
              placeholder='Given Name (required)'
              onChange={this.onGivenNameChanged.bind(this)}
              required='required'
              />
            <input
              type='text'
              className='field'
              placeholder='Surname (required)'
              onChange={this.onSurnameChanged.bind(this)}
              required='required'
              />
            <input
              type='email'
              className='field'
              placeholder='Email (required)'
              onChange={this.onEmailChanged.bind(this)}
              required='required'
              />
            <input
              type='password'
              className='field'
              placeholder='Password (required)'
              onChange={this.onPasswordChanged.bind(this)}
              required='required'
              />
            <input
              type='password'
              className='field'
              placeholder='Confirm Password (required)'
              onChange={this.onConfirmPasswordChanged.bind(this)}
              required='required'
              />
          </div>
          {
            this.state.errorMessage !== null
              ? <p style={{color: 'red'}}>{this.state.errorMessage}</p>
              : null
          }
          <input type='submit' className='login-button' value='Create Account'/>
          <br/>
          Already have an account? <a href='#' onClick={switchToLoginForm}>Log in.</a>
        </form>
        <LoginInfo/>
      </div>
    )
  }
}

export default RegistrationForm
