import React from 'react'
import Reflux from 'reflux'
import ReactMixin from 'react-mixin'
import bindAll from 'lodash/bindAll'
import ClassNames from 'classnames'
import Question from '../question/question'
import QuestionNumberGroup from '../question/question-number-group'
import QuestionActions from '../../actions/question-actions'
import QuestionStore from '../../stores/question-store'
import Loader from '../toolbox/loader'

@ReactMixin.decorate(Reflux.connect(QuestionStore))
class ExamContent extends React.Component {

  constructor (props) {
    super(props)
    bindAll(this)
  }

  static get propTypes () {
    return {
      exam: React.PropTypes.string.isRequired,
      subject: React.PropTypes.string.isRequired,
      year: React.PropTypes.string.isRequired,
      topic: React.PropTypes.string
    }
  }

  componentDidMount () {
      QuestionActions.retrieveQuestions(this.props.exam, this.props.subject, this.props.year, this.props.topic)
  }

  render () {
    const { questions, currentQuestion, questionsMetadata } = this.state
    let classString = ClassNames({
      'exam-content': true,
      'hasPassages': this.state.currentQuestion.information !== null
    })
    return (
      <div className={classString}>
        <div className='wrap'>
        {questions.length > 0
          ? (<div>
              <QuestionNumberGroup
                questions={questions}
                currentSlide={currentQuestion.index}
                questionsMetadata={questionsMetadata}
                />
              <div key={currentQuestion.index}>
                <div className='question-wrap'>
                  <Question
                    question={currentQuestion}
                    index={currentQuestion.index}
                    metadata={questionsMetadata[currentQuestion.index]}
                    />
                </div>
              </div>
            </div>) : <Loader/>
         }
         </div>
      </div>
    )
  }
}

export default ExamContent
