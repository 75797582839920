/* */ 
var baseMatches = require("./baseMatches"),
    baseMatchesProperty = require("./baseMatchesProperty"),
    identity = require("../identity"),
    isArray = require("../isArray"),
    property = require("../property");
function baseIteratee(value) {
  var type = typeof value;
  if (type == 'function') {
    return value;
  }
  if (value == null) {
    return identity;
  }
  if (type == 'object') {
    return isArray(value) ? baseMatchesProperty(value[0], value[1]) : baseMatches(value);
  }
  return property(value);
}
module.exports = baseIteratee;
