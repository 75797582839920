/* */ 
var Reflux = require("./index"),
    _ = require("./utils");
module.exports = function(listenable, key) {
  return {
    getInitialState: function() {
      if (!_.isFunction(listenable.getInitialState)) {
        return {};
      } else if (key === undefined) {
        return listenable.getInitialState();
      } else {
        return _.object([key], [listenable.getInitialState()]);
      }
    },
    componentDidMount: function() {
      _.extend(this, Reflux.ListenerMethods);
      var me = this,
          cb = (key === undefined ? this.setState : function(v) {
            if (typeof me.isMounted === "undefined" || me.isMounted() === true) {
              me.setState(_.object([key], [v]));
            }
          });
      this.listenTo(listenable, cb);
    },
    componentWillUnmount: Reflux.ListenerMixin.componentWillUnmount
  };
};
