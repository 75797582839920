/* */ 
'use strict';
var ReactDOM = require("./ReactDOM");
var ReactDOMServer = require("./ReactDOMServer");
var ReactIsomorphic = require("./ReactIsomorphic");
var assign = require("./Object.assign");
var React = {};
assign(React, ReactIsomorphic);
React.__SECRET_DOM_DO_NOT_USE_OR_YOU_WILL_BE_FIRED = ReactDOM;
React.__SECRET_DOM_SERVER_DO_NOT_USE_OR_YOU_WILL_BE_FIRED = ReactDOMServer;
module.exports = React;
