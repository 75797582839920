import Reflux from 'reflux'
import object from 'lodash/object'
import QuestionActions from '../actions/question-actions'
import { notifySuccess, notifyError } from '../util/notifier-utils'
import { QUESTION_RETRIEVAL_FAILED, QUESTION_UPDATE_SUCCESS, QUESTION_UPDATE_FAILED,TOPIC_ADDED_SUCCESS } from '../util/messages'

const QuestionStore = Reflux.createStore({
    listenables: [QuestionActions],

    questionsMetadata: [],

    questionsHistoryMetadata: [],

    questions: [],

    currentQuestion: {},

    score: 0,

    attemptedQuestion: 0,

    isExamFinished: false,

    onRetrieveQuestionsCompleted: function (response) {
        response.json().then((questions) => {
            let sortedQuestions = questions.sort(function (a, b) {
                return parseFloat(a.index) - parseFloat(b.index)
            })
            this.examInit(sortedQuestions)
            this.trigger({questions: object.values(sortedQuestions)})
        })
    },
    onFinishExamCompleted: function (response) {
        
    },
    onFinishExamFailed: function (e) {
        
    },

    onRetrieveQuestionsFailed: function (e) {
        notifyError(QUESTION_RETRIEVAL_FAILED, null, 10000)
    },

    onSetEditedQuestionText: function (editedQuestionText) {
        this.currentQuestion.text = editedQuestionText
        this.trigger({text: editedQuestionText})
    },

    onSetEditedImage: function (editedImage) {
        this.currentQuestion.image = editedImage
        this.trigger({image: editedImage})
    },

    onSetEditedChoice: function (editedChoice, choiceIndex) {
        this.currentQuestion.options[choiceIndex].text = editedChoice
        this.trigger({text: editedChoice})
    },

    onSetEditedInformation: function (editedInformation) {
        this.currentQuestion.information = editedInformation
        this.trigger({information: editedInformation})
    },
    onUpdateTopic: function (topic) {
        this.currentQuestion.topic = topic
        this.trigger({topic: topic})
        notifySuccess(TOPIC_ADDED_SUCCESS)        
    },

    onUpdateQuestionCompleted: function (response) {
        notifySuccess(QUESTION_UPDATE_SUCCESS, null, 10000)
    },

    onUpdateQuestionFailed: function (e) {
        notifyError(QUESTION_UPDATE_FAILED, null, 10000)
    },

    getInitialState: function () {
        return {
            questions: this.questions,
            questionsMetadata: this.questionsMetadata,
            currentQuestion: this.currentQuestion,
            score: this.score,
            attemptedQuestions: this.attemptedQuestions,
            resultOverlayHidden: true
        }
    },

    examInit: function (questions) {
        let start = new Date();
        this.questionsMetadata = []
        let questionsMetadata = this.questionsMetadata
        let questionsHistoryMetadata = this.questionsHistoryMetadata
        this.trigger(questionsMetadata)
        this.isExamFinished = false
        questions.map((question, key) => {
            questions[key].index = key
            let metadata = {
                questionIndex: question.index,
                selectedAnswerIndex: null,
                isAnswered: false,
                isBookmarked: false,
                isViewed: false,
                quesid: question.id
            }
            let historyData = {
                selectedOption: -1,
                questionId: question.id
            }
            this.questionsMetadata.push(metadata)
            this.questionsHistoryMetadata.push(historyData)
        })
        let currentQuestion = this.currentQuestion = questions[0]
        this.questions = questions
        let starttime = this.starttime = start.getTime()
        this.trigger({questions, questionsMetadata, questionsHistoryMetadata, currentQuestion, starttime})
    },

    onSelectAnswer: function (questionIndex, choiceIndex) {
        this.questionsMetadata[questionIndex].selectedAnswerIndex = choiceIndex
        this.questionsHistoryMetadata[questionIndex].selectedOption = choiceIndex
        this.questionsMetadata[questionIndex].isAnswered = true
        this.trigger(this.questionsMetadata[questionIndex])
        this.trigger(this.questionsHistoryMetadata[questionIndex])
    },

    bookmarkQuestion: function (questionIndex) {
        this.questionsMetadata[questionIndex].isBookmarked = !this.questionsMetadata[questionIndex].isBookmarked
        this.trigger(this.questionsMetadata[questionIndex])
    },

    onFinishExam: function () {

        this.isExamFinished = true

        this.score = 0
        this.attemptedQuestions = 0
        this.questions.map((question, key) => {
            if (this.questionsMetadata[key].selectedAnswerIndex === question.correctAnswer) {
                this.score++
            }
            if (this.questionsMetadata[key].isAnswered) {
                this.attemptedQuestions++
            }
        })
        let score = this.score
        let attemptedQuestions = this.attemptedQuestions
        this.trigger({score, attemptedQuestions, resultOverlayHidden: false})
    },

    onNextQuestion: function () {
        let newIndex = 0
        if (this.currentQuestion.index < (this.questions.length - 1)) {
            newIndex = this.currentQuestion.index + 1
        }
        let currentQuestion = this.currentQuestion = this.questions[newIndex]
        this.questionsMetadata[newIndex].isViewed = true
        this.trigger({currentQuestion})
    },

    onPrevQuestion: function () {
        let newIndex = 0
        let currentQuestion = {}
        if (this.currentQuestion.index > 0) {
            newIndex = this.currentQuestion.index - 1
        }
        if (newIndex === -1) {
            currentQuestion = this.currentQuestion = this.questions[this.questions.length - 1]
        } else {
            currentQuestion = this.currentQuestion = this.questions[newIndex]
            this.questionsMetadata[newIndex].isViewed = true
        }
        this.trigger({currentQuestion})
    },

    onGotoQuestion: function (number) {
        let currentQuestion = this.currentQuestion = this.questions[number]
        this.questionsMetadata[number].isViewed = true
        this.trigger({currentQuestion})
    }

})

export default QuestionStore
