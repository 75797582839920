import React from 'react'
import Reflux from 'reflux'
import '../../../css/onboarding.css!'
import Slider from 'nuka-carousel'
import ExamActions from '../../actions/exam-actions'
import ExamStore from '../../stores/exam-store'
import classNames from 'classnames'
import {cdnImagesUrl} from '../../api/config'

var Onboarding = React.createClass({
  mixins: [Reflux.connect(ExamStore), Slider.ControllerMixin],

  render: function () {
    let classString = classNames({
      'onboarding-container': true,
      'hidden': this.state.isOnboardingHidden
    })
    return (
      <div className={classString}>
        <div className='onboard-wrap'>
          <button className='close'><span className='icon ion-ios-close-empty' onClick={ExamActions.hideOnboarding}></span></button>
          <Slider
            ref='onboardSlider'
              data={this.setCarouselData.bind(this, 'onboardSlider')}>
            <div className='slider-item'>
              <img src={`//${cdnImagesUrl}/start_practising.png`}/>
              <div className='content'>
                <h3>Let's Get You Started</h3>
                <p>New to Distinction.ng? let's help you get acquainted with a few things</p>
              </div>
            </div>
            <div className='slider-item'>
              <img src={`//${cdnImagesUrl}/onboard2.png`}/>
              <div className='content'>
                <h3>Select Exam</h3>
                <p>Select the exam, then choose from the list of subjects to start practising</p>
              </div>
            </div>
            <div className='slider-item'>
              <img src={`//${cdnImagesUrl}/onboard3.png`}/>
              <div className='content'>
                <h3>Take Exam</h3>
                <p>Use the arrows onscreen or on your keyboard to navigate between questions. The current and answered questions are indicated on the question bar.</p>
              </div>
            </div>
            <div className='slider-item'>
              <img src={`//${cdnImagesUrl}/onboard4.png`}/>
              <div className='content'>
                <h3>View Result</h3>
                <p>After you've completed your exam, click on 'Finish Exam' to see your result</p>
              </div>
            </div>
          </Slider>
          {this.state.carousels.onboardSlider ? <button type='button' className='prev' onClick={this.state.carousels.onboardSlider.previousSlide}>
            <span className='ion-arrow-left-b'></span>
          </button> : null}
          {this.state.carousels.onboardSlider ? <button type='button' className='next' onClick={this.state.carousels.onboardSlider.nextSlide}>
            <span className='ion-arrow-right-b'></span>
          </button> : null}
          <button className='skip' onClick={ExamActions.hideOnboarding}>Skip to Exams</button>
        </div>
      </div>
    )
  }
})

export default Onboarding
