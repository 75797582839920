/* */ 
var mapClear = require("./mapClear"),
    mapDelete = require("./mapDelete"),
    mapGet = require("./mapGet"),
    mapHas = require("./mapHas"),
    mapSet = require("./mapSet");
function MapCache(values) {
  var index = -1,
      length = values ? values.length : 0;
  this.clear();
  while (++index < length) {
    var entry = values[index];
    this.set(entry[0], entry[1]);
  }
}
MapCache.prototype.clear = mapClear;
MapCache.prototype['delete'] = mapDelete;
MapCache.prototype.get = mapGet;
MapCache.prototype.has = mapHas;
MapCache.prototype.set = mapSet;
module.exports = MapCache;
