import React from 'react'
import ReviewOptionGroup from './review-option-group'

const ReviewQuestion = React.createClass({

  propTypes: {
    question: React.PropTypes.object.isRequired,
    questionMetadata: React.PropTypes.object.isRequired
  },

  renderHTML: function () {
    return {__html: this.props.question.text}
  },

  render: function () {
    let base64String = this.props.question.questionImage
    return (
        <div className='review'>
          <b>{this.props.question.index + 1}.</b>
          <span dangerouslySetInnerHTML={this.renderHTML()}/>
          {
            base64String ? <img src={'data:image/png;base64,' + base64String}></img> : ''
          }
          <ReviewOptionGroup
            options={this.props.question.options}
            questionMetadata={this.props.questionMetadata}
            correctAnswer={this.props.question.correctAnswer}
          />
        </div>
    )
  }
})

export default ReviewQuestion
