import React from 'react'

import LoginActions from '../../actions/login-actions'

export default class GoogleLogin extends React.Component {
  render () {
    return (
      <div className='social-login twitter' onClick={LoginActions.googleDirectLogin}>
        <span className='icon ion-social-google'></span> Google
      </div>
    )
  }
}
